import {Gazebo} from '@slices/Gazebo';

import {GameType} from '../constants';

import {create} from './create';

export function createGameType(
  gameType: GameType,
): Gazebo.LobbyManager.AbstractGameType {
  return gameType === 'NLHE'
    ? create(Gazebo.LobbyManager.NoLimitHoldem, {})
    : gameType === 'PLO4'
    ? create(Gazebo.LobbyManager.PotLimitOmaha4, {})
    : gameType === 'PLO6'
    ? create(Gazebo.LobbyManager.PotLimitOmaha6, {})
    : // default for Omaha
      create(Gazebo.LobbyManager.PotLimitOmaha5, {});
}
