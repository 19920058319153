import {CancelContext} from '@neolab/cancel-context';
import React from 'react';

import {
  Fn,
  PromiseValue,
  Response,
  useRequest as originalUseRequest,
} from '../ice-client-react';

/**
 * Изменение сигнатуры ice-client-react`овского useRequest для того, чтобы линтер мог проверять депсы.
 */
export const useRequest = <T extends (...args: any) => any>(
  callback: T,
  deps: React.DependencyList,
  ctx: CancelContext,
): [Response<PromiseValue<ReturnType<T>>> | undefined, Fn<T>] => {
  return originalUseRequest(ctx, callback, deps);
};
