import {HTMLTable} from '@blueprintjs/core';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {
  getResponseData,
  getResponseError,
  useSubscription,
} from '../../ice-client-react';
import {useWalletsAdminPrx} from '../../store/walletsAdmin';
import {getClasses} from '../../utils/css';
import {centsToMainUnits} from '../../utils/numbers';

import {DownloadCSVButton} from './DownloadCSVButton';
import {
  LIMIT_DEFAULT,
  PageParams,
  PagerFooter,
  PagerHeader,
  PAGES_IN_HEADER,
} from './Pager';

const classes = getClasses({
  table: {
    marginTop: '2%',
  },
});

export const LeagueTransactionsSubscribe: React.SFC<{leagueId: string}> = ({
  leagueId,
}) => {
  // const [ctx] = useCancelContext();
  const {subscribeToLeagueClubTransactions} = useWalletsAdminPrx();
  const subscribe = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.WalletsAdmin.Transaction>,
    ) => subscribeToLeagueClubTransactions(subscriber, leagueId),
    [leagueId, subscribeToLeagueClubTransactions],
  );
  const leagueUserTransactionSubscriptionState = useSubscription(subscribe);
  const leagueUserTransaction = getResponseData(
    leagueUserTransactionSubscriptionState,
  );

  return (
    <>
      <HTMLTable striped={true} condensed={true} className={classes.table}>
        <thead>
          <tr>
            <th>Executor ID</th>
            <th>Date</th>
            <th>Type</th>
            <th>Amount</th>
            <th>League ID</th>
            <th>Club ID</th>
            <th>Club Name</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Subscribe release</td>
          </tr>
          {leagueUserTransaction?.map((t) => (
            <tr key={t.transactionId}>
              <td>
                {t.actorId instanceof Gazebo.WalletsAdmin.ClubActorId
                  ? t.actorId.clubDisplayId
                  : t.actorId instanceof Gazebo.WalletsAdmin.UserActorId
                  ? t.actorId.userDisplayId
                  : 'unknown'}
              </td>
              <td>
                {moment(t.happenedAtMs.toNumber()).format('DD.MM.YYYY HH:mm')}
              </td>
              <td>
                {t.type instanceof Gazebo.WalletsAdmin.Cashout
                  ? 'Cashout'
                  : 'Deposit'}
              </td>
              <td>{centsToMainUnits(t.amount.toNumber())}</td>
              <td>
                {t instanceof Gazebo.WalletsAdmin.LeagueClubTransaction
                  ? t.leagueDisplayId
                  : 'unknown'}
              </td>
              <td>
                {t instanceof Gazebo.WalletsAdmin.LeagueClubTransaction
                  ? t.clubDisplayId
                  : 'unknown'}
              </td>
              <td>
                {t instanceof Gazebo.WalletsAdmin.LeagueClubTransaction
                  ? t.clubName
                  : 'unknown'}
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </>
  );
};
