import {Tab, Tabs} from '@blueprintjs/core';
import * as React from 'react';

import {getResponseData, getResponseError} from '../../ice-client-react';
import {useClub} from '../../store/clubs';

import {getClasses} from '../../utils/css';

import {ClubTransactionsSubscribe} from './ClubTransactions';
import {BalancePage} from './UserBalance';

import {LeagueTransactionsSubscribe} from './LeagueTransactions';

const classes = getClasses({
  tabs: {
    marginTop: '10%',
  },
});

export const BalanceManagement: React.FunctionComponent<{
  clubOrDisplayId: string;
}> = ({clubOrDisplayId}) => {
  const response = useClub(clubOrDisplayId);
  const club = getResponseData(response);
  const error = getResponseError(response);

  if (error != null) {
    return <div>Error</div>;
  }
  if (club == null) {
    return null;
  }

  return (
    <Tabs className={classes.tabs}>
      <Tab
        id="balance"
        title="Balance"
        panel={<BalancePage clubOrDisplayId={club.clubId} />}
      />
      <Tab
        id="club-transactions"
        title="Club Transactions"
        panel={<ClubTransactionsSubscribe clubId={club.clubId} />}
      />
      <Tab
        id="league-transactions"
        title="League Transactions"
        panel={
          <LeagueTransactionsSubscribe
            leagueId={club.leagueId == null ? '' : club.leagueId}
          />
        }
      />
    </Tabs>
  );
};
