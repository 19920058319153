import {Button} from '@blueprintjs/core';
import {DateRange, DateRangeInput, TimePrecision} from '@blueprintjs/datetime';
import {Ice} from 'ice';
import moment from 'moment';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';

import {useRequest} from 'src/utils/useRequest';

import {useAuthContext} from '../../auth';
import {getResponseData, getResponseError} from '../../ice-client-react';
import {useLeague} from '../../store/leagues';
import {useReportsPrx} from '../../store/reports';
import {getClasses} from '../../utils/css';
import {ResponseErrorToast} from '../../utils/toast';
import {useCancelContext} from '../../utils/useCancelContext';

const classes = getClasses({
  buttons: {
    display: 'flex',
    justifyContent: 'right',
    flexDirection: 'column',
  },
  getReport: {
    display: 'flex',
    $nest: {
      '& > *': {
        margin: '30px',
      },
    },
  },
  date: {
    display: 'flex',
    marginTop: '250px',
    $nest: {
      '& > *': {
        margin: '5px',
      },
    },
  },
});

const saveFile = (file: Uint8Array, name: string) => {
  const blob = new Blob([file], {
    type: 'application/xlsx',
  });

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;
  a.download = name;
  a.click();

  URL.revokeObjectURL(url);
};

export const Report: React.FunctionComponent<{club: Gazebo.Clubs.Club}> = ({
  club,
}) => {
  const [ctx] = useCancelContext();

  const {getClubReport, getLeagueReport} = useReportsPrx();

  const leagueResponse = useLeague(club.leagueId);
  const league = getResponseData(leagueResponse)?.[0];
  const auth = useAuthContext();

  const [dates, setDates] = React.useState<DateRange>([null, null]);
  const handleDatesChange = React.useCallback(
    (newDates: DateRange) => {
      setDates(newDates);
    },
    [setDates],
  );

  const userId = auth.idTokenPayload.sub;

  const [getReportResponse, doGetReport] = useRequest(
    async () => {
      const start = dates[0];
      const finish = dates[1];
      if (start == null || finish == null) {
        throw new Error('Incorrect dates');
      }
      const report =
        league !== undefined && userId === league.ownerId
          ? await getLeagueReport(
              new Ice.Long(start.getTime()),
              new Ice.Long(finish.getTime()),
              league.leagueId,
            )
          : await getClubReport(
              new Ice.Long(start.getTime()),
              new Ice.Long(finish.getTime()),
              club.clubId,
            );
      if (!ctx.isCanceled()) {
        saveFile(
          report,
          `report_${moment(start).format('YYYY_MM_DD_HH_mm')}-${moment(
            finish,
          ).format('YYYY_MM_DD_HH_mm')}.xlsx`,
        );
      }
    },
    [ctx, getClubReport, getLeagueReport, club, dates, league, userId],
    ctx,
  );

  return (
    <div className={classes.buttons}>
      <div className={classes.date}>
        <DateRangeInput
          formatDate={(date) => moment(date).format('YYYY-MM-DD HH:mm:ss')}
          parseDate={(str) => moment(str, 'YYYY-MM-DD HH:mm:ss').toDate()}
          closeOnSelection={false}
          onChange={handleDatesChange}
          placeholder={'YYYY-MM-DD HH:mm (moment)'}
          timePickerProps={{
            precision: TimePrecision.MINUTE,
            showArrowButtons: true,
          }}
        />
      </div>
      <div className={classes.getReport}>
        <Button
          text={'Get report'}
          onClick={doGetReport}
          disabled={dates[0] == null || dates[1] == null}
          loading={getReportResponse?.type === 'started'}
        />
      </div>
      <ResponseErrorToast response={getReportResponse} />
    </div>
  );
};
