import {
  Button,
  Card,
  Checkbox,
  Classes,
  Dialog,
  FormGroup,
  H4,
  H5,
  Icon,
  InputGroup,
  Intent,
  MenuItem,
  Switch,
  Text,
} from '@blueprintjs/core';
import {DateInput, TimePrecision} from '@blueprintjs/datetime';
import {ItemRenderer, Select} from '@blueprintjs/select';
import {Ice} from 'ice';
import moment from 'moment';
import * as React from 'react';
import {cssRule} from 'typestyle';

import {range} from 'lodash';

import {Gazebo} from '@slices/Gazebo';

import {centsToMainUnits, mainUnitsToCents} from 'src/utils/numbers';
import {useRequest} from 'src/utils/useRequest';

import {AVAILABLE_GAME_TYPES, DEFAULT_GAME_TYPE, GameType} from '../constants';
import {
  getResponseData,
  getResponseError,
  useIceProxy,
} from '../ice-client-react';
import {useClub} from '../store/clubs';
import {useLobbyManagerPrx} from '../store/lobbyManager';
import {
  useTournamentsClubLobby,
  useTournamentsLeagueLobby,
} from '../store/tournamentsLobby';
import {create} from '../utils/create';
import {createGameType} from '../utils/createGameType';
import {getClasses} from '../utils/css';
import {Form} from '../utils/form';
import {useIdempotenceKey} from '../utils/idempotencyKey';
import {
  parseCents,
  parseInteger,
  parseString,
  useInputState,
} from '../utils/inputState';
import {Stopper} from '../utils/Stopper';
import {createTableSizeValidator} from '../utils/tableSizeValidator';
import {ResponseErrorToast} from '../utils/toast';
import {useCancelContext} from '../utils/useCancelContext';

const classes = getClasses({
  root: {
    display: 'grid',
    gridGap: 25,
  },
  swimlaneContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 5,
  },
  swimlaneHeader: {
    padding: '5px 10px',
    letterSpacing: 5,
  },
  tournamentCard: {
    display: 'grid',
    gridGap: 5,
    maxWidth: 230,
    position: 'relative',
    $nest: {
      [`& .${Classes.ICON}`]: {
        marginRight: 10,
      },
    },
  },
  tournamentCardHeader: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: 5,
  },
  tournamentCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 10,
    marginRight: '0!important',
    cursor: 'pointer',
  },
});

export const TournamentsManagement: React.FunctionComponent<{
  clubOrDisplayId: string;
}> = ({clubOrDisplayId}) => {
  const response = useClub(clubOrDisplayId);
  const club = getResponseData(response);
  const error = getResponseError(response);
  const clubLobby = useTournamentsClubLobby();
  const leagueLobby = useTournamentsLeagueLobby();
  const rawTournaments = (getResponseData(clubLobby) ?? []).concat(
    getResponseData(leagueLobby) ?? [],
  );
  const tournaments = React.useMemo(() => {
    const groups: Record<string, Gazebo.Lobby.MultiTableTournament[]> = {};
    for (const tournament of rawTournaments ?? []) {
      if (tournament instanceof Gazebo.Lobby.MultiTableTournament) {
        const buyIn = `${centsToMainUnits(tournament.buyIn?.toNumber())}`;
        groups[buyIn] = groups[buyIn] ?? [];
        groups[buyIn].push(tournament);
      }
    }
    return [...Object.entries(groups)].sort(
      ([buyIn1], [buyIn2]) => -buyIn1.localeCompare(buyIn2),
    );
  }, [rawTournaments]);

  if (error != null) {
    return <div>Error</div>;
  }
  if (club == null) {
    return null;
  }
  return (
    <div className={classes.root}>
      <CreateTournamentDialog club={club} />
      {tournaments.map(([buyIn, tournaments]) => (
        <Swimlane key={buyIn} buyIn={buyIn} tournaments={tournaments} />
      ))}
    </div>
  );
};

const Swimlane: React.FunctionComponent<{
  buyIn: string;
  tournaments: Gazebo.Lobby.MultiTableTournament[];
}> = ({buyIn, tournaments}) => {
  return (
    <div>
      <H4 className={classes.swimlaneHeader}>{buyIn}</H4>
      <div className={classes.swimlaneContent}>
        {tournaments.map((tournament) => (
          <TournamentCard
            key={tournament.tournamentId}
            tournament={tournament}
          />
        ))}
      </div>
    </div>
  );
};

const TournamentCard: React.FunctionComponent<{
  tournament: Gazebo.Lobby.MultiTableTournament;
}> = ({tournament}) => {
  const [ctx] = useCancelContext();
  const key = useIdempotenceKey([tournament.tournamentId]);
  const useTournamentPrx = (tournamentId: string) =>
    useIceProxy({
      type: 'dynamic',
      proxyString: `${Gazebo.Tournaments.proxyCategory}/${tournamentId}`,
      proxyClass: Gazebo.Tournaments.TournamentPrx,
    });
  const {applyCommand} = useTournamentPrx(tournament.tournamentId);

  const [isStopperOpened, setIsStopperOpened] = React.useState<boolean>(false);
  const handleStopperOpen = React.useCallback(() => {
    setIsStopperOpened(true);
  }, [setIsStopperOpened]);
  const handleStopperClose = React.useCallback(() => {
    setIsStopperOpened(false);
  }, [setIsStopperOpened]);
  const [closeTournamentResponse, doCloseTournament] = useRequest(
    () => {
      handleStopperClose();
      return applyCommand(
        key,
        create(Gazebo.Tournaments.Command.CloseTournament, {}),
      );
    },
    [handleStopperClose, applyCommand, key],
    ctx,
  );

  if (!(tournament instanceof Gazebo.Lobby.MultiTableTournament)) {
    return null;
  }

  return (
    <Card className={classes.tournamentCard}>
      <Icon
        className={classes.tournamentCloseButton}
        icon="cross"
        onClick={handleStopperOpen}
      />
      <Stopper
        title={'Close tournament'}
        text={`Are you sure you want to close tournament ${tournament.name}?`}
        doRequest={doCloseTournament}
        handleStopperClose={handleStopperClose}
        isOpened={isStopperOpened}
      />
      <H5 className={classes.tournamentCardHeader}>
        {tournament.name}
        <Text
          className={Classes.TEXT_MUTED}
        >{`#${tournament.tournamentId}`}</Text>
      </H5>
      <TournamentStatus status={tournament.status} />
      <span>
        <Icon icon="settings" /> {tournament.gameType}
      </span>
      <span>
        <Icon icon="dollar" /> buy-in{' '}
        {centsToMainUnits(tournament.buyIn.toNumber())}
      </span>
      <span>
        <Icon icon="dollar" /> prize{' '}
        {centsToMainUnits(tournament.minPrizePool?.toNumber())}
      </span>
      <span>
        <Icon icon="time" />
        {tournament.startAtMs == null
          ? null
          : tournament.startAtMs.toNumber() <= Date.now()
          ? `started ${moment(tournament.startAtMs.toNumber()).fromNow()}`
          : `starting ${moment(tournament.startAtMs.toNumber()).fromNow()}`}
      </span>
      <ResponseErrorToast response={closeTournamentResponse} />
    </Card>
  );
};

const getStatusText = (status?: Gazebo.Lobby.AbstractTournamentStatus) =>
  status == null
    ? undefined
    : status instanceof Gazebo.Lobby.TournamentRegistrationOpen
    ? 'Registration open'
    : status instanceof Gazebo.Lobby.TournamentRegistrationClosed
    ? 'Registration is closed'
    : status instanceof Gazebo.Lobby.TournamentInProgress
    ? 'In progress'
    : status instanceof Gazebo.Lobby.TournamentGamesFinished
    ? 'Games finished'
    : undefined;

const TournamentStatus: React.FunctionComponent<{
  status: Gazebo.Lobby.MultiTableTournament['status'];
}> = ({status}) => {
  const statusText = getStatusText(status);
  return statusText ? (
    <span>
      <Icon intent={Intent.NONE} icon="info-sign" />
      {statusText}
    </span>
  ) : null;
};

const createTournamentClasses = getClasses({
  form: {
    padding: 20,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  formGroup: {
    display: 'grid',
    gridTemplateColumns: '115px 250px',
    alignItems: 'center',
  },
  dialog: {
    width: 405,
  },
  submit: {
    width: 100,
  },
  create: {
    borderRadius: 50,
    position: 'fixed',
    top: 30,
    left: 'calc(50vw + 310px)',
    zIndex: 100,
  },
});

cssRule('@media only screen and (max-width: 780px)', {
  [`.${createTournamentClasses.create}`]: {
    top: 'unset',
    left: 'unset',
    bottom: 10,
    right: 10,
  },
});

cssRule('@media only screen and (max-width: 500px)', {
  [`.${classes.swimlaneContent}`]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

const getDefaultStartDate = () => {
  const now = moment();
  const roundedMinutes = Math.round(now.minutes() / 15) * 15; // 0, 15, 30, 45, 60

  return now
    .startOf('hour')
    .add(2, 'hours')
    .add(roundedMinutes, 'minutes')
    .toDate();
};

const GameSelect = Select.ofType<string>();
//начало следующего часа
const availableBlindStructures = ['Turbo'];
const defaultBlindStructure = availableBlindStructures[0];
const possibleLevelDurationMin = range(3, 11); // 3-10 minutes
const LevelDurationSelect = Select.ofType<number>();
const defaultLevelDuration = 5; // 5 min
const possibleRake = range(0, 11); // 0-10 %
const RakeSelect = Select.ofType<number>();
const defaultRake = 0; // 0% - rake disabled
const dateTimeFormat = 'DD.MM.YYYY HH:mm:ss';

const BreakSelect = Select.ofType<number>();
const possibleBreakShift = range(0, 60, 5);
const defaultBreakShift = 55;

const renderGameType: ItemRenderer<string> = (gameType, {handleClick}) => {
  return <MenuItem key={gameType} onClick={handleClick} text={gameType} />;
};

const renderLevelDuration: ItemRenderer<number> = (
  levelDuration,
  {handleClick},
) => {
  return (
    <MenuItem key={levelDuration} onClick={handleClick} text={levelDuration} />
  );
};
const renderRake: ItemRenderer<number> = (rake, {handleClick}) => {
  return <MenuItem key={rake} onClick={handleClick} text={rake} />;
};
const renderBreakShift: ItemRenderer<number> = (rake, {handleClick}) => {
  return (
    <MenuItem
      key={rake}
      onClick={handleClick}
      text={rake.toString().padStart(2, '0')}
    />
  );
};

const renderBlindStructure: ItemRenderer<string> = (
  blindStructure,
  {handleClick},
) => {
  return (
    <MenuItem
      key={blindStructure}
      onClick={handleClick}
      text={blindStructure}
    />
  );
};

const defaultIsRebuyOn = true;

const CreateTournamentDialog: React.FunctionComponent<{
  club: Gazebo.Clubs.Club;
}> = ({club}) => {
  const [isOpened, setIsOpened] = React.useState<boolean>(false);
  const {createTournament} = useLobbyManagerPrx();
  const [ctx] = useCancelContext();
  const [onNameChanged, name, setName] = useInputState(
    '',
    parseString,
    validateName,
  );
  name.error = validateName(name.value);

  const [onPrizeChanged, prize] = useInputState(
    '100',
    parseCents,
    validatePrize,
  );

  const [onMinParticipantsChanged, minParticipantsNumber] = useInputState(
    '10',
    parseInteger,
    validateMinParticipantsNumber,
  );

  const [onMaxParticipantsChanged, maxParticipantsNumber] = useInputState(
    '100',
    parseInteger,
    validateMaxParticipantsNumber,
  );

  const [isRebuyOn, setIsRebuyOn] = React.useState<boolean>(defaultIsRebuyOn);

  const [onRebuyCountChange, rebuyCount] = useInputState(
    '10',
    parseInteger,
    validatePositive,
  );

  const [onRebuyCostChange, rebuyCost] = useInputState(
    '1000',
    parseInteger,
    validatePositive,
  );

  const [onRebuyBlindLevelsChange, rebuyBlindLevels] = useInputState(
    '4',
    parseInteger,
    validatePositive,
  );

  const [gameType, setGameType] = React.useState<GameType>(DEFAULT_GAME_TYPE);
  const handleGameTypeChange = React.useCallback(
    (newGame: unknown) => {
      setGameType(newGame as GameType);
    },
    [setGameType],
  );

  const [onSizeChanged, size] = useInputState(
    '6',
    parseInteger,
    createTableSizeValidator(gameType).validate,
  );

  const hasErrors = !!(
    name.error ||
    prize.error ||
    minParticipantsNumber.error ||
    maxParticipantsNumber.error ||
    size.error ||
    maxParticipantsNumber.value < minParticipantsNumber.value
  );

  const [startDate, setStartDate] = React.useState<Date>(getDefaultStartDate());
  const handleStartDateChange = React.useCallback(
    (newStartDate: Date) => {
      setStartDate(newStartDate);
    },
    [setStartDate],
  );

  const [lateRegistration, setLateRegistration] =
    React.useState<boolean>(false);
  const handleLateRegistrationChange = React.useCallback(() => {
    setLateRegistration(!lateRegistration);
  }, [lateRegistration, setLateRegistration]);
  const [onLateRegistrationLevel, lateRegistrationLevel] = useInputState(
    '3',
    parseInteger,
    validatePositive,
  );

  const [onBuyInChanged, buyIn] = useInputState(
    '100',
    parseInteger,
    validatePositiveOrZero,
  );

  const [onStartStackChanged, startStack] = useInputState(
    '100',
    parseInteger,
    validatePositive,
  );

  const [blindStructure, setBlindStructure] = React.useState<string>(
    defaultBlindStructure,
  );
  const handleBlindStructureChange = React.useCallback(
    (newValue: unknown) => {
      setBlindStructure(newValue as string);
    },
    [setBlindStructure],
  );

  const [rake, setRake] = React.useState<number>(defaultRake);
  const handleRakeChange = React.useCallback(
    (newValue: unknown) => {
      setRake(newValue as number);
    },
    [setRake],
  );
  const handleRebuyOnChange = React.useCallback(() => {
    setIsRebuyOn(!isRebuyOn);
  }, [setIsRebuyOn, isRebuyOn]);

  const [breakShift, setBreakShift] = React.useState<number>(defaultBreakShift);
  const handleBreakChange = React.useCallback(
    (newValue: unknown) => {
      setBreakShift(newValue as number);
    },
    [setBreakShift],
  );

  const [levelDuration, setLevelDuration] =
    React.useState<number>(defaultLevelDuration);
  const handleLevelDurationChange = React.useCallback(
    (newValue: unknown) => {
      setLevelDuration(newValue as number);
    },
    [setLevelDuration],
  );

  const idempotenceKey = useIdempotenceKey([
    club.clubId,
    name,
    prize,
    buyIn,
    size,
    startDate,
  ]);
  const [createTournamentRequest, doCreateTournament] = useRequest(
    () => {
      if (hasErrors) {
        alert('Input error');
        return;
      }
      const gameTypeObject = createGameType(gameType);

      const blindStructureObject =
        blindStructure === 'Turbo'
          ? create(Gazebo.LobbyManager.TurboBlindStructure, {
              levelDurationAtMs: new Ice.Long(levelDuration * 60 * 1000),
              blindsLevelSeq: [], // пока не используется
            })
          : create(Gazebo.LobbyManager.NormalBlindStructure, {});

      // TODO сделать возможность создания турнира для клуба внутри лиги
      const gameOwnerId =
        club.leagueId != null
          ? create(Gazebo.LobbyManager.Owner.LeagueId, {
              leagueId: club.leagueId,
            })
          : create(Gazebo.LobbyManager.Owner.ClubId, {
              clubId: club.clubId,
            });
      return createTournament(
        idempotenceKey,
        create(Gazebo.LobbyManager.MultiTableTournamentParams, {
          name: name.value,
          blindStructure: blindStructureObject,
          startStack: new Ice.Long(mainUnitsToCents(startStack.value)),
          buyIn: new Ice.Long(mainUnitsToCents(buyIn.value)),
          rake:
            rake > 0
              ? create(Gazebo.LobbyManager.BuyInTournamentRake, {
                  size: rake * 100,
                })
              : undefined,
          breakSettings: createBreak(breakShift),
          tableSize: size.value,
          gameType: gameTypeObject,
          minPrizePool: new Ice.Long(prize.value),
          minParticipantsNumber: minParticipantsNumber.value,
          maxParticipantsNumber: maxParticipantsNumber.value,
          startAtMs: new Ice.Long(startDate.getTime()),
          gameOwnerId,
          rebuySettings: isRebuyOn
            ? create(Gazebo.LobbyManager.SimpleRebuySettings, {
                blindLevel: rebuyBlindLevels.value,
                timeoutMs: new Ice.Long(30000),
                count: rebuyCount.value,
                amount: new Ice.Long(rebuyCost.value),
              })
            : undefined,
          lateRegistrationParams:
            (lateRegistration &&
              create(Gazebo.LobbyManager.BlindLevelLateRegistrationParams, {
                availableUntilBlindLevel: lateRegistrationLevel.value,
              })) ||
            undefined,
        }),
      );
    },
    [
      lateRegistration,
      lateRegistrationLevel,
      idempotenceKey,
      club,
      name,
      startStack,
      buyIn,
      breakShift,
      rake,
      levelDuration,
      blindStructure,
      size,
      gameType,
      prize,
      minParticipantsNumber,
      maxParticipantsNumber,
      startDate,
      hasErrors,
      createTournament,
      isRebuyOn,
      rebuyBlindLevels,
      rebuyCount,
      rebuyCost,
    ],
    ctx,
  );

  React.useEffect(() => {
    if (createTournamentRequest?.type === 'data') {
      setName({rawValue: '', value: ''});
      setIsOpened(false);
    }
  }, [createTournamentRequest, setName, setIsOpened]);

  const onAir = createTournamentRequest?.type === 'started';

  const handleOpen = React.useCallback(() => {
    setIsOpened(true);
    setStartDate(getDefaultStartDate());
  }, [setIsOpened, setStartDate]);
  const handleClosed = React.useCallback(() => {
    if (!onAir) {
      setIsOpened(false);
    }
  }, [setIsOpened, onAir]);

  return (
    <>
      <Button
        className={createTournamentClasses.create}
        intent={Intent.PRIMARY}
        large={true}
        onClick={handleOpen}
        icon="plus"
      />
      <Dialog
        isOpen={isOpened}
        className={createTournamentClasses.dialog}
        onClose={handleClosed}
        title="Create tournament"
      >
        <Form className={createTournamentClasses.form}>
          <FormGroup
            className={createTournamentClasses.formGroup}
            inline={true}
            labelFor="text-input"
            label="Name"
            helperText={name.error}
          >
            <InputGroup
              autoFocus={true}
              value={name.rawValue}
              id="text-input"
              onChange={onNameChanged}
            />
          </FormGroup>
          <FormGroup
            className={createTournamentClasses.formGroup}
            inline={true}
            labelFor="text-input"
            label="Game"
          >
            <GameSelect
              filterable={false}
              items={AVAILABLE_GAME_TYPES}
              onItemSelect={handleGameTypeChange}
              itemRenderer={renderGameType}
            >
              <Button text={gameType} rightIcon="caret-down" />
            </GameSelect>
          </FormGroup>
          <FormGroup
            className={createTournamentClasses.formGroup}
            inline={true}
            labelFor="text-input"
            label="Buy in"
          >
            <InputGroup
              value={buyIn.rawValue}
              id="text-input"
              onChange={onBuyInChanged}
            />
          </FormGroup>
          <FormGroup
            className={createTournamentClasses.formGroup}
            labelFor="text-input"
            label="Rake"
          >
            <RakeSelect
              filterable={false}
              items={possibleRake}
              onItemSelect={handleRakeChange}
              itemRenderer={renderRake}
            >
              <Button text={rake} rightIcon="caret-down" />
            </RakeSelect>
          </FormGroup>
          <FormGroup
            className={createTournamentClasses.formGroup}
            labelFor="text-input"
            label="Break 5 min each hour at"
          >
            <BreakSelect
              filterable={false}
              items={possibleBreakShift}
              onItemSelect={handleBreakChange}
              itemRenderer={renderBreakShift}
            >
              <Button
                text={breakShift.toString().padStart(2, '0')}
                rightIcon="caret-down"
              />
            </BreakSelect>
          </FormGroup>

          <FormGroup
            className={createTournamentClasses.formGroup}
            inline={false}
            labelFor="text-input"
            label="Rebuy"
          >
            <Checkbox
              label="Enabled: "
              checked={isRebuyOn}
              onChange={handleRebuyOnChange}
              defaultChecked={defaultIsRebuyOn}
            />
            {'Count: '}
            <InputGroup
              disabled={!isRebuyOn}
              value={rebuyCount.rawValue}
              id="text-input"
              onChange={onRebuyCountChange}
            />
            {'Cost: '}
            <InputGroup
              disabled={!isRebuyOn}
              value={rebuyCost.rawValue}
              id="text-input"
              onChange={onRebuyCostChange}
            />
            {'Duration in blind levels: '}
            <InputGroup
              disabled={!isRebuyOn}
              value={rebuyBlindLevels.rawValue}
              id="text-input"
              onChange={onRebuyBlindLevelsChange}
            />
          </FormGroup>
          <FormGroup
            className={createTournamentClasses.formGroup}
            inline={true}
            labelFor="text-input"
            label="Guarantee"
            helperText={prize.error}
          >
            <InputGroup
              id="numeric-input"
              onChange={onPrizeChanged}
              value={prize.rawValue}
            />
          </FormGroup>
          <FormGroup
            className={createTournamentClasses.formGroup}
            labelFor="text-input"
            label="Blind structure"
          >
            <GameSelect
              filterable={false}
              items={availableBlindStructures}
              onItemSelect={handleBlindStructureChange}
              itemRenderer={renderBlindStructure}
            >
              <Button text={blindStructure} rightIcon="caret-down" />
            </GameSelect>
          </FormGroup>
          <FormGroup
            className={createTournamentClasses.formGroup}
            labelFor="text-input"
            label="Level duration"
          >
            <LevelDurationSelect
              filterable={false}
              items={possibleLevelDurationMin}
              onItemSelect={handleLevelDurationChange}
              itemRenderer={renderLevelDuration}
            >
              <Button text={levelDuration} rightIcon="caret-down" />
            </LevelDurationSelect>
          </FormGroup>
          <FormGroup
            className={createTournamentClasses.formGroup}
            inline={true}
            labelFor="text-input"
            label="Start stack"
          >
            <InputGroup
              value={startStack.rawValue}
              id="text-input"
              onChange={onStartStackChanged}
            />
          </FormGroup>
          <FormGroup
            className={createTournamentClasses.formGroup}
            label="Late registration"
          >
            <Switch
              checked={lateRegistration}
              onChange={handleLateRegistrationChange}
            />
          </FormGroup>
          <FormGroup
            className={createTournamentClasses.formGroup}
            label="Up to level"
          >
            <InputGroup
              disabled={!lateRegistration}
              value={lateRegistrationLevel.rawValue}
              id="text-input"
              onChange={onLateRegistrationLevel}
            />
          </FormGroup>
          <FormGroup
            className={createTournamentClasses.formGroup}
            inline={true}
            labelFor="text-input"
            label="Table size"
            helperText={size.error}
          >
            <InputGroup
              value={size.rawValue}
              id="text-input"
              onChange={onSizeChanged}
            />
          </FormGroup>
          <FormGroup
            className={createTournamentClasses.formGroup}
            label="Start date"
          >
            <DateInput
              formatDate={(date) => moment(date).format(dateTimeFormat)}
              closeOnSelection={false}
              onChange={handleStartDateChange}
              parseDate={(str) => moment(str, dateTimeFormat).toDate()}
              placeholder={'DD.MM.YYYY HH:mm:ss (moment)'}
              timePickerProps={{
                precision: TimePrecision.SECOND,
                showArrowButtons: true,
              }}
              defaultValue={startDate}
              minDate={new Date()}
            />
          </FormGroup>
          <FormGroup
            className={createTournamentClasses.formGroup}
            labelFor="text-input"
            label="Minimun participants"
            helperText={
              minParticipantsNumber.error ??
              (maxParticipantsNumber.value < minParticipantsNumber.value
                ? 'Maximum participants must be greater than or equal to minumum'
                : '')
            }
          >
            <InputGroup
              id="numeric-input"
              onChange={onMinParticipantsChanged}
              value={minParticipantsNumber.rawValue}
            />
          </FormGroup>
          <FormGroup
            className={createTournamentClasses.formGroup}
            labelFor="text-input"
            label="Maximum participants"
            helperText={
              maxParticipantsNumber.error ??
              (maxParticipantsNumber.value < minParticipantsNumber.value
                ? 'Maximum participants must be greater than or equal to minumum'
                : '')
            }
          >
            <InputGroup
              id="numeric-input"
              onChange={onMaxParticipantsChanged}
              value={maxParticipantsNumber.rawValue}
            />
          </FormGroup>
          <Button
            loading={onAir}
            disabled={hasErrors}
            className={createTournamentClasses.submit}
            type="submit"
            intent={Intent.PRIMARY}
            onClick={doCreateTournament}
          >
            Create
          </Button>
          <ResponseErrorToast response={createTournamentRequest} />
        </Form>
      </Dialog>
    </>
  );
};

const MS_IN_MINUTE = 60 * 1000;
const createBreak = (shift: number): Gazebo.LobbyManager.AstronomicBreak => {
  return create(Gazebo.LobbyManager.AstronomicBreak, {
    breakEachMs: new Ice.Long(shift * MS_IN_MINUTE),
    durationMs: new Ice.Long(5 * MS_IN_MINUTE),
    periodicityLengthMs: new Ice.Long(60 * MS_IN_MINUTE),
  });
};

const validatePositiveOrZero = (size: number) => {
  if (Number.isNaN(size) || size < 0 || !Number.isInteger(size)) {
    return 'size must be a positive integer or zero';
  }
  return;
};

const validatePositive = (size: number) => {
  if (Number.isNaN(size) || size <= 0 || !Number.isInteger(size)) {
    return 'size must be a positive integer';
  }
  return;
};

const validateMinParticipantsNumber = (participants: number) => {
  if (
    Number.isNaN(participants) ||
    participants <= 1 ||
    !Number.isInteger(participants)
  ) {
    return 'Min participants must be an integer greater than 1';
  }
  return;
};

const validateMaxParticipantsNumber = (participants: number) => {
  if (
    Number.isNaN(participants) ||
    participants <= 1 ||
    !Number.isInteger(participants)
  ) {
    return 'Max participants must be an integer greater than 1';
  }
  return;
};

const validatePrize = (prize: number) => {
  if (Number.isNaN(prize) || prize < 0) {
    return 'prize pool must be a positive number';
  }
  if (prize > 100_000_000) {
    return 'prize pool must be less than or equal to 1.000.000';
  }
  return;
};

const validateName = (name: string) => {
  if (name.length < 4) {
    return 'tournament name must contain at least 4 characters';
  }
  return;
};
