import {Gazebo} from '@slices/Gazebo';

import {useIceProxy} from '../ice-client-react';

export const useUserInfoPrx = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.UserInfo',
    proxyClass: Gazebo.UserInfo.ServicePrx,
  });
