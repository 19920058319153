import {Ice} from 'ice';
import pino, {levels, LogDescriptor, Logger} from 'pino';

function serializeError(error: any, visited: Set<any> = new Set()) {
  if (!(error instanceof Error)) {
    return error;
  }

  const {
    stack,
    cause,
    ice_cause: iceCause,
    _inToStringAlready,
    ...ret
  } = error as any;

  if (error instanceof Ice.Exception) {
    ret['@class'] = error.ice_id().slice(2).replace(/::/g, '.');
  } else {
    if (error.message) {
      ret.message = error.message;
    }

    if (ret.name == null && error.constructor) {
      ret.name = error.constructor.name || 'Error';
    }
  }

  let causedBy = undefined;

  if (iceCause) {
    causedBy = iceCause;
  } else if (cause) {
    if (typeof cause === 'function') {
      causedBy = cause();
    } else {
      causedBy = cause;
    }
  }

  // handle circular references
  if (causedBy === error || (visited != null && visited.has(causedBy))) {
    causedBy = undefined;
  }

  if (causedBy) {
    const nextVisited = new Set(visited).add(error);
    ret.cause = serializeError(causedBy, nextVisited);
  }

  if (stack) {
    if (typeof stack !== 'string') {
      ret.stack = stack;
    } else {
      let finalStack = stack;

      // remove non-stacktrace lines
      while (!finalStack.startsWith('    at ')) {
        const newlineIndex = finalStack.indexOf('\n');

        if (newlineIndex === -1) {
          finalStack = '';
          break;
        } else {
          finalStack = finalStack.slice(finalStack.indexOf('\n') + 1);
        }
      }

      // remove common tail with cause stack
      if (causedBy && typeof causedBy.stack === 'string') {
        let causeStack: string = causedBy.stack;
        let stripped = false;

        while (true) {
          const idx = finalStack.lastIndexOf('\n');
          const causeIdx = causeStack.lastIndexOf('\n');

          if (finalStack.slice(idx + 1) === causeStack.slice(causeIdx + 1)) {
            finalStack = finalStack.slice(0, idx);
            causeStack = causeStack.slice(0, causeIdx);

            stripped = true;

            if (idx === -1 || causeIdx === -1) {
              break;
            }
          } else {
            break;
          }
        }

        if (stripped) {
          finalStack += '\n    ...';
        }
      }

      ret.stack = finalStack;
    }
  }

  return ret;
}

export const logger = pino({
  browser: {
    write: (obj: any) => {
      const {
        hostname: _hostname,
        level,
        msg,
        pid: _pid,
        time: _time,
        v: _v,
        ...rest
      } = obj as LogDescriptor;

      if ('err' in rest) {
        rest.err = serializeError(rest.err);
      }

      const levelLabel = levels.labels[level];
      const logFnName = getConsoleFnName(levelLabel);

      if (Object.keys(rest).length) {
        console[logFnName](`[${levelLabel.toUpperCase()}] ${msg}\n`, rest);
      } else {
        console[logFnName](`[${levelLabel.toUpperCase()}] ${msg}`);
      }
    },
  },
});

(window as any).logger = logger;

const getConsoleFnName = (
  levelLabel: string,
): 'info' | 'log' | 'error' | 'warn' | 'debug' | 'trace' => {
  switch (levelLabel) {
    case 'info':
    case 'error':
    case 'debug':
    case 'warn':
      return levelLabel;
    case 'fatal':
      return 'error';
    default:
      return 'log';
  }
};

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const logger: Logger;
}
