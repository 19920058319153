import _ from 'lodash';

import {Gazebo} from '@slices/Gazebo';

import {create} from './create';

type GameOptions = {
  bombPot?: {
    period: number;
    sizeInBB: number;
    activatesDoubleBoard?: boolean;
  };
  doubleBoard?: boolean;
};

export function createGameOptions(
  gameOptions: GameOptions,
): Gazebo.LobbyManager.GameOptions | undefined {
  const convertedGameOptions = {} as any;
  if (gameOptions.bombPot) {
    convertedGameOptions.bombPot = create(
      Gazebo.LobbyManager.BombPotGameOption,
      {
        period: gameOptions.bombPot.period,
        bombAnteSizeInBB: gameOptions.bombPot.sizeInBB,
        activatesDoubleBoard: gameOptions.bombPot.activatesDoubleBoard,
      },
    );
  }
  if (gameOptions.doubleBoard) {
    convertedGameOptions.doubleBoard = create(
      Gazebo.LobbyManager.DoubleBoardGameOption,
      {},
    );
  }
  if (_.isEqual(convertedGameOptions, {})) {
    return undefined;
  }
  return create(Gazebo.LobbyManager.GameOptions, convertedGameOptions);
}
