import {Ice} from 'ice';

/**
 * Fix marshalling of classes with no own fields.
 * See https://github.com/zeroc-ice/ice/pull/22
 */
Object.assign(Ice.Value.prototype, {
  _iceWrite(this: any, os: any) {
    os.startValue(null);
    writeImpl(this, os, this._iceMostDerivedType());
    os.endValue();
  },

  _iceRead(this: any, is: any) {
    is.startValue();
    readImpl(this, is, this._iceMostDerivedType());
    is.endValue(false);
  },
});

function writeImpl(obj: any, os: any, type: any) {
  //
  // The writeImpl method is a recursive method that goes down the
  // class hierarchy to marshal each slice of the class using the
  // generated _iceWriteMemberImpl method.
  //

  if (type === undefined || type === Ice.Value) {
    return; // Don't marshal anything for Ice.Value
  }

  os.startSlice(
    type.ice_staticId(),
    Object.prototype.hasOwnProperty.call(type, '_iceCompactId')
      ? type._iceCompactId
      : -1,
    Object.getPrototypeOf(type) === Ice.Value,
  );
  // eslint-disable-next-line no-prototype-builtins
  if (type.prototype.hasOwnProperty('_iceWriteMemberImpl')) {
    type.prototype._iceWriteMemberImpl.call(obj, os);
  }
  os.endSlice();
  writeImpl(obj, os, Object.getPrototypeOf(type));
}

function readImpl(obj: any, is: any, type: any) {
  //
  // The readImpl method is a recursive method that goes down the
  // class hierarchy to unmarshal each slice of the class using the
  // generated _iceReadMemberImpl method.
  //

  if (type === undefined || type === Ice.Value) {
    return; // Don't unmarshal anything for Ice.Value
  }

  is.startSlice();
  // eslint-disable-next-line no-prototype-builtins
  if (type.prototype.hasOwnProperty('_iceReadMemberImpl')) {
    type.prototype._iceReadMemberImpl.call(obj, is);
  }
  is.endSlice();
  readImpl(obj, is, Object.getPrototypeOf(type));
}

function writePreserved(this: any, os: any) {
  //
  // For Slice classes which are marked "preserved", the implementation of this
  // method
  // replaces the Ice.Value.prototype._iceWrite method.
  //
  os.startValue(this._iceSlicedData);
  writeImpl(this, os, this._iceMostDerivedType());
  os.endValue();
}

function readPreserved(this: any, is: any) {
  //
  // For Slice classes which are marked "preserved", the implementation of this
  // method
  // replaces the Ice.Value.prototype._iceRead method.
  //
  is.startValue();
  readImpl(this, is, this._iceMostDerivedType());
  this._iceSlicedData = is.endValue(true);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function ice_getSlicedData(this: any) {
  return this._iceSlicedData;
}

(Ice as any).Slice.defineValue = (
  valueType: any,
  id: any,
  preserved: any,
  compactId: any = 0,
) => {
  valueType.prototype.ice_id = () => {
    return id;
  };

  valueType.prototype._iceMostDerivedType = () => {
    return valueType;
  };

  valueType.ice_staticId = () => {
    return id;
  };

  if (preserved) {
    valueType.prototype.ice_getSlicedData = ice_getSlicedData;
    valueType.prototype._iceWrite = writePreserved;
    valueType.prototype._iceRead = readPreserved;
  }

  if (compactId > 0) {
    (Ice as any).CompactIdRegistry.set(compactId, id);
  }
};
