import {buildIceProperties} from './ice-client';

export const iceProperties = buildIceProperties('Webapp');

iceProperties.setProperty('Ice.Warn.Connections', '1');
iceProperties.setProperty('Ice.Warn.Dispatch', '1');
iceProperties.setProperty('Ice.Default.Host', window.location.hostname);
iceProperties.setProperty('Ice.RetryIntervals', '-1');

export const a = () => {};
