import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';

import {Subscriptions} from '@slices/Subscriptions';

import {
  getResponseData,
  SubscriptionState,
  useIceProxy,
  useSubscription,
} from '../ice-client-react';

const ClubsContext = React.createContext<SubscriptionState<Gazebo.Clubs.Club>>({
  type: 'started',
});

export const ClubsProvider: React.SFC = ({children}) => {
  const {subscribeToClubs} = useClubsProxy();

  const subscribe = React.useMemo(
    () =>
      (subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Clubs.Club>) =>
        subscribeToClubs(subscriber, undefined),
    [subscribeToClubs],
  );

  const subscription = useSubscription(subscribe);

  return (
    <ClubsContext.Provider value={subscription}>
      {children}
    </ClubsContext.Provider>
  );
};

export const useClubs = () => {
  return React.useContext(ClubsContext);
};

export const useClub = (clubId?: string) => {
  const clubs = useClubs();
  return React.useMemo(() => {
    if (clubId == null) {
      return undefined;
    }
    const data = getResponseData(clubs) ?? [];
    return {
      ...clubs,
      data: data.find(
        (club) => club.clubId === clubId || club.displayId === clubId,
      ),
    };
  }, [clubs, clubId]);
};

export const useClubsProxy = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.Clubs',
    proxyClass: Gazebo.Clubs.ServicePrx,
  });
