import {Gazebo} from '@slices/Gazebo';

import {useIceProxy} from '../ice-client-react';

export const useWalletsAdminPrx = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.WalletsAdmin',
    proxyClass: Gazebo.WalletsAdmin.ServicePrx,
  });
