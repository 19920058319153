import {Ice} from 'ice';

const IceLong = Ice.Long;

Ice.Long = class Long extends IceLong {
  static [Symbol.hasInstance](instance: any) {
    return instance instanceof IceLong;
  }

  constructor(high = 0, low?: number) {
    if (low === undefined) {
      // tslint:disable
      low = high >>> 0;
      high = ((high - low) / 0x100000000) >>> 0;
      // tslint:enable
    }

    super(high, low);
  }
};
