import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {
  SubscriptionState,
  useIceProxy,
  useSubscription,
} from '../ice-client-react';

import {useClub} from './clubs';

const CreateLobbyContext = () =>
  React.createContext<SubscriptionState<Gazebo.Lobby.PokerTable>>({
    type: 'started',
  });

const ClubLobbyContext = CreateLobbyContext();
const LeagueLobbyContext = CreateLobbyContext();

export const ClubLobbyProvider: React.SFC<{
  clubOrDisplayId: string | undefined;
}> = ({children, clubOrDisplayId}) => {
  const club = useClub(clubOrDisplayId)?.data;
  const subscribeToClub = React.useMemo(
    () => (club == null ? undefined : CreateSubscribeFunction([club.clubId])),
    [club],
  );
  const subscribeToLeague = React.useMemo(
    () =>
      club != null && club.leagueId
        ? CreateSubscribeFunction([club.leagueId])
        : undefined,
    [club],
  );

  const clubLobbySubscription = useSubscription(subscribeToClub);
  const leagueLobbySubscription = useSubscription(subscribeToLeague);

  return (
    <ClubLobbyContext.Provider value={clubLobbySubscription}>
      <LeagueLobbyContext.Provider value={leagueLobbySubscription}>
        {children}
      </LeagueLobbyContext.Provider>
    </ClubLobbyContext.Provider>
  );
};

export const useClubLobby = () => {
  return React.useContext(ClubLobbyContext);
};

export const useLeagueLobby = () => {
  return React.useContext(LeagueLobbyContext);
};

const CreateSubscribeFunction = (areaIds: string[]) => {
  const {subscribeToManagerTables} = useIceProxy<Gazebo.Lobby.ServicePrx>({
    type: 'static',
    propertyName: 'Webapp.Lobby',
    proxyClass: Gazebo.Lobby.ServicePrx,
  });

  return (
    subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Lobby.AbstractTable>,
  ) => subscribeToManagerTables(subscriber, areaIds);
};
