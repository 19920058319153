import {Button, Dialog, Intent, Text} from '@blueprintjs/core';
import * as React from 'react';

import {getClasses} from './css';
import {Form} from './form';

const classes = getClasses({
  root: {
    width: 350,
  },
  stopperPortal: {
    zIndex: 1000,
  },
  stopperText: {
    padding: 20,
    fontSize: 18,
    textAlign: 'center',
  },
  stopperForm: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingLeft: 40,
    paddingRight: 40,
  },
  stopperButton: {
    width: 100,
  },
});

export const Stopper: React.FunctionComponent<{
  title: string;
  text: string;
  doRequest: () => void;
  handleStopperClose: () => void;
  isOpened: boolean;
}> = ({title, text, doRequest, handleStopperClose, isOpened}) => {
  return (
    <Dialog
      isOpen={isOpened}
      className={classes.root}
      onClose={handleStopperClose}
      title={title}
      usePortal={true}
      portalContainer={document.body}
      portalClassName={classes.stopperPortal}
    >
      <Text className={classes.stopperText}>{text}</Text>
      <Form className={classes.stopperForm}>
        <Button
          className={classes.stopperButton}
          type="button"
          intent={Intent.PRIMARY}
          onClick={handleStopperClose}
        >
          Cancel
        </Button>
        <Button
          className={classes.stopperButton}
          type="submit"
          intent={Intent.PRIMARY}
          onClick={doRequest}
        >
          Confirm
        </Button>
      </Form>
    </Dialog>
  );
};
