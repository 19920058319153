import {Gazebo} from '@slices/Gazebo';

import {useIceProxy} from '../ice-client-react';

export const useTablePrx = (tableId: string) =>
  useIceProxy({
    type: 'dynamic',
    proxyString: `Table/${tableId}`,
    proxyClass: Gazebo.Tables.Poker.TablePrx,
  });
