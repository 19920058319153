import {Button} from '@blueprintjs/core';
import {get} from 'lodash';
import * as React from 'react';

import {getResponseData} from '../../ice-client-react';
import {useClub} from '../../store/clubs';
import {downloadAsCsv} from '../../utils/csv';

import {useBalanceInfo} from './BalanceInfoContext';

// название короткое, чтобы не разъехалась таблица
const DOWNLOAD_CSV_CAPTION = 'CSV';
const CSV_FILE_NAME = 'players_balances.csv';

export const UserBalancesDownload: React.FunctionComponent<{
  clubId: string;
  caption?: string;
}> = ({clubId, caption}) => {
  const response = useClub(clubId);
  const club = getResponseData(response);

  const {userBalancesSubscriptionState, userInfosSubscriptionState} =
    useBalanceInfo();

  const userDetails = React.useMemo(
    () =>
      new Map(
        getResponseData(userInfosSubscriptionState)?.map((user) => [
          user.userId,
          {displayName: user.displayName, displayId: user.displayId},
        ]),
      ),
    [userInfosSubscriptionState],
  );

  const downloadData: any = React.useMemo(() => {
    const data =
      getResponseData(userBalancesSubscriptionState)?.map((wallet) => {
        const userId = wallet.ownerId.userId ?? '';
        return [
          get(userDetails.get(userId), 'displayName', wallet.ownerId.userId),
          get(userDetails.get(userId), 'displayId', wallet.ownerId.userId),
          wallet.balance.toNumber() / 100,
        ];
      }) || [];

    return [['nickname', 'playerid', 'balance'], ...data];
  }, [userBalancesSubscriptionState, userDetails]);

  const doDownloadCSV = React.useCallback(() => {
    downloadAsCsv(CSV_FILE_NAME, downloadData);
  }, [downloadData]);

  if (!club) {
    return null;
  }

  return (
    <Button
      icon="download"
      text={caption || DOWNLOAD_CSV_CAPTION}
      disabled={downloadData.length < 2}
      onClick={doDownloadCSV}
    />
  );
};
