import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import {Ice} from 'ice';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {useRequest} from 'src/utils/useRequest';

import {useAuthContext} from '../../auth';
import {
  getResponseData,
  getResponseError,
  useSubscription,
} from '../../ice-client-react';
import {logger} from '../../logger';
import {useLeague} from '../../store/leagues';
import {useWalletsAdminPrx} from '../../store/walletsAdmin';
import {create} from '../../utils/create';
import {getClasses} from '../../utils/css';
import {Form} from '../../utils/form';
import {useIdempotenceKey} from '../../utils/idempotencyKey';
import {parseCents, parseInteger, useInputState} from '../../utils/inputState';
import {centsToMainUnits} from '../../utils/numbers';
import {ResponseErrorToast} from '../../utils/toast';
import {useCancelContext} from '../../utils/useCancelContext';
import {
  BalanceInfoProvider,
  useBalanceInfo,
} from '../balance/BalanceInfoContext';

const classes = getClasses({
  form: {
    $nest: {
      [`& .${Classes.FORM_GROUP}.${Classes.INLINE}`]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      },
    },
  },
  refillGroup: {
    display: 'grid',
    gridTemplateColumns: '1fr 80px 80px',
    gridGap: 10,
  },
  balanceMain: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    height: 30,
  },
  balanceTooltip: {
    paddingBottom: '6px',
  },
});

export const BalanceManagement: React.FC<{club: Gazebo.Clubs.Club}> = ({
  club,
}) => {
  const {leagueId, clubId} = club;
  const leagueResponse = useLeague(leagueId);
  const league = getResponseData(leagueResponse)?.[0];

  if (league == null) {
    return <div>Error</div>;
  }

  return (
    <BalanceInfoProvider clubId={clubId}>
      <LeagueBalance leagueId={leagueId!} />
      <ClubBalances league={league} />
    </BalanceInfoProvider>
  );
};

const LeagueBalance: React.SFC<{leagueId: string}> = ({leagueId}) => {
  const [ctx] = useCancelContext();
  const {
    changeLeagueBalance,
    subscribeToLeagueBalances,
    transferFromUserToLeague,
  } = useWalletsAdminPrx();
  const auth = useAuthContext();
  const myUserId = auth.idTokenPayload.sub;
  const subscribe = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.WalletsAdmin.Wallet>,
    ) => subscribeToLeagueBalances(subscriber, [leagueId]),
    [leagueId, subscribeToLeagueBalances],
  );
  const balanceSubscriptionState = useSubscription(subscribe);
  const {leagueGemBalancesSubscriptionState, userGemBalancesSubscriptionState} =
    useBalanceInfo();

  const leagueWallet = getResponseData(balanceSubscriptionState);

  const leagueGemWallet = getResponseData(leagueGemBalancesSubscriptionState);
  const myGemWallet = getResponseData(userGemBalancesSubscriptionState);

  const [changeGemState, gemAmount, , setGemAmount] = useInputState(
    '0',
    parseInteger,
    validateAmount,
  );
  const gemKey = useIdempotenceKey(['gems', leagueId, gemAmount]);
  const [gemTransferState, transferGems] = useRequest(
    async () => {
      if (gemAmount.error == null && gemAmount.value > 0) {
        await transferFromUserToLeague(
          gemKey,
          leagueId,
          myUserId,
          new Ice.Long(gemAmount.value),
          create(Gazebo.WalletsAdmin.Gems, {}),
        );
        setGemAmount('0');
      }
    },
    [
      transferFromUserToLeague,
      setGemAmount,
      gemAmount,
      gemKey,
      leagueId,
      myUserId,
    ],
    ctx,
  );

  const [changeState, amount, setAmount] = useInputState(
    '0.00',
    parseCents,
    validateAmount,
  );
  const key = useIdempotenceKey([leagueId, amount]);
  const [balanceUpdatingState, updateBalance] = useRequest(
    async () => {
      if (amount.error == null && amount.value > 0) {
        await changeLeagueBalance(key, leagueId, new Ice.Long(amount.value));
        setAmount({...amount, value: 0, error: ''});
      }
    },
    [setAmount, amount, changeLeagueBalance, key, leagueId],
    ctx,
  );

  const leagueGemsLoading =
    gemTransferState?.type === 'started' ||
    leagueGemBalancesSubscriptionState.type === 'started';

  const userGemsLoading =
    gemTransferState?.type === 'started' ||
    userGemBalancesSubscriptionState.type === 'started';

  const loading =
    balanceUpdatingState?.type === 'started' ||
    balanceSubscriptionState.type === 'started';

  const err = getResponseError(balanceSubscriptionState);
  if (err) {
    logger.warn({err}, 'Got error in subscription to league balance');
  }

  const label = (
    <>
      League balance:{' '}
      <span className={loading ? Classes.TEXT_MUTED : ''}>
        {err
          ? 'error'
          : leagueWallet?.[0]?.balance.toNumber() === undefined
          ? ''
          : centsToMainUnits(leagueWallet?.[0]?.balance.toNumber())}
      </span>
    </>
  );
  const leagueGemBalance = leagueGemWallet?.[0]?.balance.toNumber();
  const leagueGemLabel = (
    <>
      League Gem balance:{' '}
      <span className={leagueGemsLoading ? Classes.TEXT_MUTED : ''}>
        {err ? 'error' : leagueGemBalance === undefined ? '' : leagueGemBalance}
      </span>
    </>
  );
  const myGemBalance = myGemWallet?.[0]?.balance.toNumber();
  const myGemLabel = (
    <>
      My Gem balance:{' '}
      <span className={userGemsLoading ? Classes.TEXT_MUTED : ''}>
        {err ? 'error' : myGemBalance === undefined ? '' : myGemBalance}
      </span>
    </>
  );

  return (
    <Form className={classes.form}>
      <FormGroup
        inline={true}
        label={label}
        labelFor="text-input"
        helperText={amount.error}
      >
        <div className={classes.refillGroup}>
          <InputGroup
            autoFocus={true}
            value={`${amount.rawValue}`}
            id="text-input"
            onChange={changeState}
          />
          <Button
            type="submit"
            disabled={amount.error != null}
            onClick={updateBalance}
          >
            Refill
          </Button>
        </div>
      </FormGroup>
      <FormGroup inline={true} label={leagueGemLabel} labelFor="text-input">
        <div className={classes.refillGroup}>
          <InputGroup
            autoFocus={true}
            value={`${gemAmount.rawValue}`}
            id="text-input"
            onChange={changeGemState}
          />
          <Button
            type="submit"
            disabled={gemAmount.error != null}
            onClick={transferGems}
          >
            Transfer
          </Button>
        </div>
      </FormGroup>
      <FormGroup inline={true} label={myGemLabel} labelFor="text-input" />
      <ResponseErrorToast response={balanceUpdatingState} />
      <ResponseErrorToast response={gemTransferState} />
    </Form>
  );
};

const ClubBalances: React.SFC<{league: Gazebo.Leagues.League}> = ({league}) => {
  const {subscribeToClubBalances} = useWalletsAdminPrx();

  const subscribeToBalances = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.WalletsAdmin.Wallet>,
    ) =>
      subscribeToClubBalances(
        subscriber,
        league.clubs.map(({clubId}) => clubId),
        league.leagueId,
      ),
    [subscribeToClubBalances, league],
  );
  const balancesSubscriptionState = useSubscription(subscribeToBalances);

  return (
    <>
      {getResponseData(balancesSubscriptionState)?.map((wallet) => (
        <ClubBalance
          key={wallet.ownerId.clubId}
          clubId={wallet.ownerId.clubId ?? ''}
          leagueId={league.leagueId}
          clubBalance={wallet.balance}
          clubInfo={league.leagueClubsInfo?.find(
            (clubInfo) => clubInfo.clubId === wallet.ownerId.clubId,
          )}
        />
      ))}
    </>
  );
};

const ClubBalance: React.SFC<{
  leagueId: string;
  clubId: string;
  clubBalance?: Ice.Long;
  clubInfo?: Gazebo.Leagues.CommonClubInfo;
}> = ({leagueId, clubId, clubBalance, clubInfo}) => {
  const {transferFromLeagueToClub, transferFromClubToLeague} =
    useWalletsAdminPrx();

  const [ctx] = useCancelContext();

  const [onAmountChange, amount, , setAmount] = useInputState(
    '0.00',
    parseCents,
    validateAmount,
  );

  const keyFromClubToUser = useIdempotenceKey([leagueId, amount]);
  const [depositState, doDeposit] = useRequest(
    async () => {
      if (amount.error == null && amount.value > 0) {
        await transferFromLeagueToClub(
          keyFromClubToUser,
          leagueId,
          clubId,
          new Ice.Long(amount.value),
        );
        setAmount('0.00');
      }
    },
    [
      transferFromLeagueToClub,
      keyFromClubToUser,
      setAmount,
      amount,
      leagueId,
      clubId,
    ],
    ctx,
  );

  const keyFromUserToClub = useIdempotenceKey([leagueId, amount]);
  const [cashoutState, doCashout] = useRequest(
    async () => {
      if (amount.error == null && amount.value > 0) {
        await transferFromClubToLeague(
          keyFromUserToClub,
          leagueId,
          clubId,
          new Ice.Long(amount.value),
        );
        setAmount('0.00');
      }
    },
    [
      transferFromClubToLeague,
      keyFromUserToClub,
      setAmount,
      amount,
      leagueId,
      clubId,
    ],
    ctx,
  );

  const loading =
    depositState?.type === 'started' || cashoutState?.type === 'started';

  const label = (
    <span className={loading ? Classes.TEXT_MUTED : ''}>
      <div className={classes.balanceMain}>
        <div className={classes.balanceTooltip}>
          <Tooltip content={clubId} position={Position.TOP}>
            {`${clubInfo?.name ?? clubId} (${clubInfo?.displayId ?? ''}) `}
          </Tooltip>
        </div>
        <div>
          {' : ' +
            (clubBalance?.toNumber() === undefined
              ? ''
              : centsToMainUnits(clubBalance?.toNumber()))}
        </div>
      </div>
    </span>
  );

  return (
    <Form className={classes.form}>
      <FormGroup
        inline={true}
        labelFor="text-input"
        label={label}
        helperText={amount.error}
      >
        <div className={classes.refillGroup}>
          <InputGroup
            value={`${amount.rawValue}`}
            id="text-input"
            onChange={onAmountChange}
          />
          <Button
            type="submit"
            disabled={amount.error != null}
            onClick={doDeposit}
          >
            Deposit
          </Button>
          <Button
            type="submit"
            disabled={amount.error != null}
            onClick={doCashout}
          >
            Cashout
          </Button>
        </div>
      </FormGroup>
      <ResponseErrorToast response={depositState} />
      <ResponseErrorToast response={cashoutState} />
    </Form>
  );
};

const validateAmount = (value: number) => {
  if (Number.isNaN(value) || value <= 0 || !Number.isInteger(value)) {
    // Тут валидируется распарсенное значение, поэтому сообщение об ошибке не
    // полностью соответствует условию.
    return 'amount must be a positive number';
  }
  return;
};
