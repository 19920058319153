import {
  Cancel,
  CancelContext,
  catchCancelError,
  createCancelContext,
  execute,
  isCancelError,
} from '@neolab/cancel-context';
import {Ice} from 'ice';

import {AuthExceptions} from '@slices/AuthExceptions/Exceptions';

export type Deferred<T = void> = {
  promise: Promise<T>;
  resolve: T extends void ? () => void : (value: T) => void;
  reject: (error: unknown) => void;
};
export const deferred = <T = void>(ctx: CancelContext): Deferred<T> => {
  let resolve: any;
  let reject: any;
  const promise = execute<T>(ctx, (innerResolve, innerReject) => {
    resolve = innerResolve;
    reject = innerReject;
  });
  return {
    promise,
    resolve,
    reject,
  };
};

export type Subject<T> = {
  put(value: T): void;
  throwError(err?: any): void;
  next(): Promise<T>;
  current(): Promise<T>;
  ctx: CancelContext;
  cancel: Cancel;
  map<T1>(fn: (params: T) => T1): Subject<T1>;
  merge<T1, R>(
    sibling: Subject<T1>,
    merge: (value1: T, value2: T1) => R,
  ): Subject<R>;
};

export const createSubject = <T>(outerCtx?: CancelContext): Subject<T> => {
  const [ctx, cancel] = createCancelContext();
  const removeCancelCallback = outerCtx?.onCancel(cancel) ?? (() => {});
  let next = deferred<T>(ctx);
  let current = next;
  next.promise.catch(catchCancelError);
  const put = (data: T) => {
    next.resolve(data);
    current = next;
    next = deferred(ctx);
    next.promise.catch(catchCancelError);
  };

  const throwError = (err?: any) => {
    next.reject(err);
    current = next;
    next = deferred(ctx);
    next.promise.catch(catchCancelError);
  };

  const subject: Subject<T> = {
    put,
    throwError,
    current: () => current.promise,
    next: () => next.promise,
    ctx,
    cancel: (err?: any) => {
      if (ctx.isCanceled()) {
        return;
      }
      removeCancelCallback();
      cancel(err);
    },
    map: <T1>(fn: (params: T) => T1): Subject<T1> => {
      const nextSubject = createSubject<T1>(subject.ctx);
      (async () => {
        let promise = subject.current();
        while (!nextSubject.ctx.isCanceled()) {
          try {
            nextSubject.put(fn(await promise));
          } catch (err) {
            nextSubject.throwError(err);
          }
          promise = subject.next();
        }
      })();
      return nextSubject;
    },
    merge: <T1, R>(
      sibling: Subject<T1>,
      merge: (value1: T, value2: T1) => R,
    ): Subject<R> => {
      type S1 = {id: '1'} & (
        | {type: 'data'; data: T}
        | {type: 'error'; error?: any}
      );
      type S2 = {id: '2'} & (
        | {type: 'data'; data: T1}
        | {type: 'error'; error?: any}
      );
      const subject1 = subject.map(
        (data) => ({id: '1', type: 'data', data} as const),
      );
      const subject2 = sibling.map(
        (data) => ({id: '2', type: 'data', data} as const),
      );
      const result = createSubject<R>();
      const removeCancelCallback1 = subject1.ctx.onCancel(result.cancel);
      const removeCancelCallback2 = subject2.ctx.onCancel(result.cancel);

      (async () => {
        const catchError = (id: '1' | '2') => (error?: any) =>
          ({id, type: 'error', error} as const);
        let state: {1?: S1; 2?: S2} = {};
        let promise1 = subject1.current(),
          promise2 = subject2.current();
        while (!result.ctx.isCanceled()) {
          const update = await Promise.race([
            promise1.catch(catchError('1')),
            promise2.catch(catchError('2')),
          ]);
          promise1 = subject1.next();
          promise2 = subject2.next();
          state = {...state, [update.id]: update};
          if (state[1]?.type === 'data' && state[2]?.type === 'data') {
            result.put(merge(state[1].data, state[2].data));
          } else {
            if (update?.type === 'error') {
              result.throwError(update.error);
            }
            if (state[1] == null) {
              promise1 = subject1.current();
            } else if (state[2] == null) {
              promise2 = subject2.current();
            }
          }
        }
      })();

      return {
        ...result,
        cancel: (err?: any) => {
          if (result.ctx.isCanceled()) {
            return;
          }
          removeCancelCallback1();
          removeCancelCallback2();
          result.cancel(err);
        },
      };
    },
  };
  return subject;
};

const returnNewPrx: Array<keyof Ice.ObjectPrx> = [
  'ice_identity',
  'ice_context',
  'ice_facet',
  'ice_adapterId',
  'ice_endpoints',
  'ice_locatorCacheTimeout',
  'ice_invocationTimeout',
  'ice_endpointSelection',
  'ice_secure',
  'ice_preferSecure',
  'ice_router',
  'ice_locator',
  'ice_twoway',
  'ice_oneway',
  'ice_batchOneway',
  'ice_datagram',
  'ice_batchDatagram',
  'ice_timeout',
  'ice_connectionId',
];

type Promisify<F> = F extends (...args: infer Args) => infer Res
  ? Res extends Promise<infer R>
    ? (...args: Args) => Promise<R>
    : (...args: Args) => Promise<Res>
  : Promise<F>;

export type PromisifyPrx<C extends Ice.ObjectPrx> = {
  [K in keyof C]: Promisify<C[K]>;
} & {$$iceProxy: Promise<C>; $$unbind(): void};

export const bindProxy = <P extends Ice.ObjectPrx>(
  constructor: Ice.ObjectPrxConstructor<P>,
  subject: Subject<P>,
): PromisifyPrx<P> => {
  const remoteCalls: {[K in keyof P]?: any} = {};
  return new Proxy<PromisifyPrx<P>>({} as any, {
    get(_target: PromisifyPrx<P>, propName: symbol | string): any {
      if (propName === '$$iceProxy') {
        return subject.current();
      }
      if (propName === '$$unbind') {
        return subject.cancel;
      }
      // Just return prop value
      if (!(typeof constructor.prototype[propName] === 'function')) {
        return subject.current().then((prx) => (prx as any)[propName]);
      }

      // Return cached bindings
      if ((remoteCalls as any)[propName]) {
        return (remoteCalls as any)[propName];
      }

      const method: any = constructor.prototype[propName];

      // Bind remote call
      if (!returnNewPrx.includes(propName as any)) {
        (remoteCalls as any)[propName] = async (...args: any[]) => {
          try {
            const response: Ice.AsyncResult<any> = method.call(
              await subject.current(),
              ...args,
            );

            (async (removeCancelCallback) => {
              try {
                await response;
              } catch {
              } finally {
                removeCancelCallback();
              }
            })(
              subject.ctx.onCancel(() => {
                response.cancel();
              }),
            );

            return response;
          } catch (err) {
            if (isCancelError(err)) {
              throw new Ice.InvocationCanceledException();
            }
            throw err;
          }
        };
        return (remoteCalls as any)[propName];
      }

      // Proxy chain

      return (...args: any[]): PromisifyPrx<P> => {
        const nextSubject = subject.map<P>((prx) => method.call(prx, ...args));
        return bindProxy<P>(constructor, nextSubject);
      };
    },
  });
};

/** @internal */
export function logError(
  message: string,
  e: Error,
  level: 'error' | 'warn' | 'info' | 'debug' = 'error',
) {
  const args: any[] = [];

  let sagaStack: string = (e as any).sagaStack;
  if (sagaStack) {
    if (e.stack) {
      sagaStack = sagaStack.replace(e.stack, '');
    }
    args.push(`${sagaStack}\n`);
  }

  args.push(e);
  console[level](`${message}\n`, ...args);
}

/** @internal */
export function getAuthExceptionsModule(): typeof AuthExceptions {
  return (Ice as any)._ModuleRegistry.type('AuthExceptions');
}
