import {Ice} from 'ice';

import {DynamicProxyConfig, StaticProxyConfig} from './proxyConfig';
/**
 * Special exception created when `proxy` is `null`.
 */
export class NullPrxError extends Error {
  constructor(
    public config:
      | StaticProxyConfig<Ice.ObjectPrx>
      | DynamicProxyConfig<Ice.ObjectPrx>,
  ) {
    super('NullPrxError');

    this.message = '';

    this.name = this.constructor.name;

    if (typeof (Error as any).captureStackTrace === 'function') {
      (Error as any).captureStackTrace(this, this.constructor);
    }
  }
}

export function isNullPrxError(error: any): error is NullPrxError {
  return (
    typeof error === 'object' && error !== null && error.name === 'NullPrxError'
  );
}

/**
 * If `error` is `NullPrxError`, throw it. Otherwise do nothing.
 *
 * Useful for `try/catch` blocks around cancelable code:
 *
 *    try {
 *      await somethingNullPrxable(ctx);
 *    } catch (err) {
 *      rethrowNullPrxError(err);
 *
 *      // do normal error handling
 *    }
 */
export function rethrowNullPrxError(error: any): void {
  if (isNullPrxError(error)) {
    throw error;
  }

  return;
}

/**
 * If `error` is `NullPrxError`, do nothing. Otherwise rethrow it.
 *
 * Useful for invoking top-level cancelable functions:
 *
 *    somethingNullPrxable(ctx).catch(catchNullPrxError)
 *
 * Without `catchNullPrxError`, cancellation would result in unhandled promise
 * rejection.
 */
export function catchNullPrxError(error: any) {
  if (isNullPrxError(error)) {
    return;
  }

  throw error;
}
