import {DEFAULT_GAME_TYPE, GameType} from '../constants';

export function createTableSizeValidator(type: GameType = DEFAULT_GAME_TYPE) {
  const message = 'size must be';
  return {
    validate: (size: number) => {
      if (Number.isNaN(size) || size <= 0) {
        return `${message} a positivie number`;
      }
      if (size < 2) {
        return `${message} greater than 1`;
      }

      if (type === 'PLO6' && size > 6) {
        return `${message} less than 7`;
      }

      if (type === 'PLO5' && size > 8) {
        return `${message} less than 9`;
      }

      if (size > 9) {
        return `${message} less than 10`;
      }
      return;
    },
  };
}
