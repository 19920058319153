import {Card, Elevation, H3} from '@blueprintjs/core';
import * as React from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';

import {getResponseData, getResponseError} from '../ice-client-react';
import {ClubsProvider, useClubs} from '../store/clubs';
import {getClasses} from '../utils/css';
import {nullableToList} from '../utils/emptyList';

import {BalanceManagement} from './balance';
import {Layout} from './layout';
import {LeagueManagement} from './league';
import {PlayersManagement} from './players';
import {TablesManagement} from './tables';
import {TournamentsManagement} from './tournaments';

const useRouter = () => {
  const match = useRouteMatch<{clubOrDisplayId: string; workspace?: string}>(
    '/:clubOrDisplayId/:workspace?',
  );
  const clubOrDisplayId = match?.params.clubOrDisplayId;
  const workspace = match?.params.workspace;

  const response = useClubs();

  const clubs = nullableToList(getResponseData(response));

  const history = useHistory();

  React.useEffect(() => {
    if (response.type !== 'data') {
      return;
    }
    if (clubOrDisplayId != null) {
      if (
        clubs.some(
          (club) =>
            club.clubId === clubOrDisplayId ||
            club.displayId === clubOrDisplayId,
        )
      ) {
        if (workspace == null) {
          history.push(`/${clubOrDisplayId}/players`);
        }
      } else {
        history.push('/');
      }
    } else if (clubs.length === 1) {
      const [club] = clubs;
      history.push(`/${club.clubId}/players`);
    }
  }, [history, clubs, clubOrDisplayId, workspace, response]);
};

const Content: React.SFC = () => {
  useRouter();

  const match = useRouteMatch<{clubOrDisplayId: string; workspace: string}>(
    '/:clubOrDisplayId/:workspace',
  );
  if (match != null) {
    const {workspace, clubOrDisplayId} = match.params;
    switch (workspace) {
      case 'players':
        return <PlayersManagement clubOrDisplayId={clubOrDisplayId} />;
      case 'balance':
        return <BalanceManagement clubOrDisplayId={clubOrDisplayId} />;
      case 'tables':
        return <TablesManagement clubOrDisplayId={clubOrDisplayId} />;
      case 'tournaments':
        return <TournamentsManagement clubOrDisplayId={clubOrDisplayId} />;
      case 'league':
        return <LeagueManagement clubOrDisplayId={clubOrDisplayId} />;
      default:
        return <>Invalid route path</>;
    }
  }
  return <Root />;
};

const classes = getClasses({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: 5,
  },
});

const Root = () => {
  const response = useClubs();

  const clubs = getResponseData(response) ?? [];
  const error = getResponseError(response);

  const history = useHistory();

  if (error != null) {
    return <div>Error</div>;
  }

  return (
    <div className={classes.root}>
      {clubs.map((club) => (
        <Card
          key={club.clubId}
          interactive={true}
          elevation={Elevation.TWO}
          onClick={() => history.push(`/${club.clubId}/players`)}
        >
          <H3>
            {club.name} #{club.displayId}
          </H3>
          <p>
            <span>{club.userIds.length} Players</span>
            <br />
            <span>{club.userWaitingApprovalIds.length} Pending requests</span>
          </p>
        </Card>
      ))}
    </div>
  );
};

export const Routes = () => (
  <ClubsProvider>
    <Layout>
      <Content />
    </Layout>
  </ClubsProvider>
);
