import {saveAs} from 'file-saver';
import {unparse} from 'papaparse';

function csvToBlob(csvData: string, useBom = false): Blob {
  const data = useBom ? `${'\ufeff'}${csvData}` : csvData;
  return new Blob([data], {type: 'text/csv;charset=utf-8'});
}

function downloadCsv(filename: string, csvData: string, useBom = true) {
  saveAs(csvToBlob(csvData, useBom), filename);
}

export function downloadAsCsv(filename: string, data: any, useBom = true) {
  downloadCsv(filename, unparse(data), useBom);
}
