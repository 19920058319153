import {Ice} from 'ice';

import {Plain} from './Plain';

export function create<T extends Ice.Value | Ice.Exception | Ice.Struct>(
  constructor: {new (...args: any[]): T},
  values: Plain<T>,
): T {
  return Object.assign(new constructor(), values);
}
