export function centsToMainUnits(value?: number): string {
  return ((value ?? 0) / 100).toFixed(2);
}

export function mainUnitsToCents(value: number): number {
  return Math.round(value * 100);
}

// Форматирует величину рейка, принимаемую с сервера.
export const formatRakeSize = (value?: number): string => {
  return `${((value ?? 0) / 100).toFixed(2)}%`;
};

// Парсит величину рейка, вводимую пользователем.
export const parseRakeSize = (value: string): number => {
  return Math.round(parseFloat(value) * 100);
};

// Форматирует кепку рейка, принимаемую с сервера.
export const formatRakeCap = (value?: number): string => {
  return `${((value ?? 0) / 100).toFixed(2)}BB`;
};

// Парсит кепку рейка, вводимую пользователем.
export const parseRakeCap = (value: string): number => {
  return parseRakeSize(value);
};
