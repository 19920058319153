import {Ice} from 'ice';

/**
 * Fix Ice exception stack traces
 */
if ((Error as any).captureStackTrace) {
  Object.assign(Ice.Exception, {
    captureStackTrace(obj: any) {
      (Error as any).captureStackTrace(obj, obj.constructor);
    },
  });
}

/**
 * Fix marshalling of exceptions with no own fields.
 * See https://github.com/zeroc-ice/ice/pull/22
 */
Object.assign(Ice.UserException.prototype, {
  _write(this: any, os: any) {
    os.startException(null);
    writeImpl(this, os, this._mostDerivedType());
    os.endException();
  },

  _read(this: any, is: any) {
    is.startException();
    readImpl(this, is, this._mostDerivedType());
    is.endException(false);
  },
});

function writeImpl(obj: any, os: any, type: any) {
  //
  // The writeImpl method is a recursive method that goes down the
  // class hierarchy to marshal each slice of the class using the
  // generated _writeMemberImpl method.
  //

  if (type === undefined || type === Ice.UserException) {
    return; // Don't marshal anything for Ice.UserException
  }

  os.startSlice(type._id, -1, type._parent === Ice.UserException);
  // eslint-disable-next-line no-prototype-builtins
  if (type.prototype.hasOwnProperty('_writeMemberImpl')) {
    type.prototype._writeMemberImpl.call(obj, os);
  }
  os.endSlice();
  writeImpl(obj, os, type._parent);
}

function readImpl(obj: any, is: any, type: any) {
  //
  // The readImpl method is a recursive method that goes down the
  // class hierarchy to marshal each slice of the class using the
  // generated _readMemberImpl method.
  //

  if (type === undefined || type === Ice.UserException) {
    return; // Don't marshal anything for UserException
  }

  is.startSlice();
  // eslint-disable-next-line no-prototype-builtins
  if (type.prototype.hasOwnProperty('_readMemberImpl')) {
    type.prototype._readMemberImpl.call(obj, is);
  }
  is.endSlice();
  readImpl(obj, is, type._parent);
}

function writePreserved(this: any, os: any) {
  //
  // For Slice exceptions which are marked "preserved", the implementation of
  // this method
  // replaces the Ice.UserException.prototype._write method.
  //
  os.startException(this._slicedData);
  writeImpl(this, os, this._mostDerivedType());
  os.endException();
}

function readPreserved(this: any, is: any) {
  //
  // For Slice exceptions which are marked "preserved", the implementation of
  // this method
  // replaces the Ice.UserException.prototype._read method.
  //
  is.startException();
  readImpl(this, is, this._mostDerivedType());
  this._slicedData = is.endException(true);
}

const iceGetSlicedData = function (this: any) {
  return this._slicedData;
};

(Ice as any).Slice.PreservedUserException = function (this: any, ex: any) {
  ex.prototype.ice_getSlicedData = iceGetSlicedData;
  ex.prototype._write = writePreserved;
  ex.prototype._read = readPreserved;
};
