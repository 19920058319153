import * as React from 'react';

export const Form: React.SFC<
  React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >
> = ({children, onSubmit, ...props}) => {
  const updatedOnSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      if (typeof onSubmit === 'function') {
        return onSubmit(event);
      }
    },
    [onSubmit],
  );
  return (
    <form onSubmit={updatedOnSubmit} {...props}>
      {children}
    </form>
  );
};
