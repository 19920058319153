import * as React from 'react';

export type InputState<T> = {value: T; rawValue: string; error?: string};
export type SetInput<T> = React.Dispatch<React.SetStateAction<InputState<T>>>;
export type SetRawValue<T> = (rawValue: string) => void;
// TODO: Исправить валидацию, чтобы валидировалось значение, введённое
//  пользователем. Это позволит выводить пользователю ошибки, говорящие
//  непосредственно о введённом значении.
export const useInputState = <T,>(
  defaultRawValue: string,
  parse: (raw: string) => T,
  validate: (value: T) => string | undefined,
): [(event: any) => void, InputState<T>, SetInput<T>, SetRawValue<T>] => {
  const value = parse(defaultRawValue);
  const [state, setState] = React.useState<InputState<T>>({
    value,
    rawValue: defaultRawValue,
    error: '',
  });

  const setRawValue = React.useCallback(
    (rawValue) => {
      try {
        const value = parse(rawValue);
        const error = validate(value);
        setState({rawValue, value, error});
      } catch (err) {
        if (typeof err === 'object' && err != null && 'message' in err) {
          setState({rawValue, value: rawValue, error: (err as any).message});
        } else {
          setState({rawValue, value: rawValue, error: `${err}`});
        }
      }
    },
    [setState, parse, validate],
  );

  const changeState = React.useCallback(
    (event) => {
      setRawValue(event.target.value);
    },
    [setRawValue],
  );

  // TODO: не выставлять наружу setState.
  return [changeState, state, setState, setRawValue];
};

export const parseString = (value: string) => value;
export const parseBoolean = (value: any) =>
  value === true || value === 'true' || value === 1 || value === '1';
export const skipValidation = (_value: any) => undefined;
export const parseInteger = (value: string) => parseInt(value, 10);
export const parseCents = (value: string) => parseFloat(value) * 100;
