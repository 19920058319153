import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {
  SubscriptionState,
  useIceProxy,
  useSubscription,
} from '../ice-client-react';

import {useClub} from './clubs';

const CreateLobbyContext = () =>
  React.createContext<SubscriptionState<Gazebo.Lobby.AbstractTournament>>({
    type: 'started',
  });

const TournamentsClubLobbyContext = CreateLobbyContext();
const TournamentsLeagueLobbyContext = CreateLobbyContext();

export const TournamentsLobbyProvider: React.FunctionComponent<{
  clubOrDisplayId: string | undefined;
}> = ({children, clubOrDisplayId}) => {
  const club = useClub(clubOrDisplayId)?.data;

  const subscribeToClub = React.useMemo(
    () => (club == null ? undefined : CreateSubscribeFunction([club.clubId])),
    [club],
  );
  const subscribeToLeague = React.useMemo(
    () =>
      club != null && club.leagueId
        ? CreateSubscribeFunction([club.leagueId])
        : undefined,
    [club],
  );

  const clubLobbySubscription = useSubscription(subscribeToClub);
  const leagueLobbySubscription = useSubscription(subscribeToLeague);

  return (
    <TournamentsClubLobbyContext.Provider value={clubLobbySubscription}>
      <TournamentsLeagueLobbyContext.Provider value={leagueLobbySubscription}>
        {children}
      </TournamentsLeagueLobbyContext.Provider>
    </TournamentsClubLobbyContext.Provider>
  );
};

export const useTournamentsClubLobby = () => {
  return React.useContext(TournamentsClubLobbyContext);
};

export const useTournamentsLeagueLobby = () => {
  return React.useContext(TournamentsLeagueLobbyContext);
};

const CreateSubscribeFunction = (areaIds: string[]) => {
  const {subscribeToTournaments} = useIceProxy<Gazebo.Lobby.ServicePrx>({
    type: 'static',
    propertyName: 'Webapp.Lobby',
    proxyClass: Gazebo.Lobby.ServicePrx,
  });

  return (
    subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Lobby.AbstractTournament>,
  ) => subscribeToTournaments(subscriber, areaIds);
};
