import {Colors, HTMLTable} from '@blueprintjs/core';
import moment from 'moment';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {getResponseData, useSubscription} from '../../ice-client-react';
import {useWalletsAdminPrx} from '../../store/walletsAdmin';
import {getClasses} from '../../utils/css';
import {centsToMainUnits} from '../../utils/numbers';

const classes = getClasses({
  table: {
    marginTop: '2%',
  },
  rowColor: {
    backgroundColor: Colors.BLUE1,
  },
});

export const ClubTransactionsSubscribe: React.SFC<{clubId: string}> = ({
  clubId,
}) => {
  // const [ctx] = useCancelContext();
  const {subscribeToClubUserTransactions} = useWalletsAdminPrx();
  const subscribe = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.WalletsAdmin.Transaction>,
    ) => subscribeToClubUserTransactions(subscriber, clubId),
    [clubId, subscribeToClubUserTransactions],
  );
  const clubUserTransactionSubscriptionState = useSubscription(subscribe);
  const clubUserTransaction = getResponseData(
    clubUserTransactionSubscriptionState,
  );

  const sortedTransactions = React.useMemo(
    () =>
      [...(clubUserTransaction ?? [])].sort(
        (a, b) => b.happenedAtMs.toNumber() - a.happenedAtMs.toNumber(),
      ),
    [clubUserTransaction],
  );

  return (
    <>
      <HTMLTable striped={true} condensed={true} className={classes.table}>
        <thead>
          <tr>
            <th>Executor ID</th>
            <th>Date</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Player ID</th>
            <th>Nickname</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Subscribe release</td>
          </tr>
          {sortedTransactions?.map((t) => (
            <tr key={t.transactionId}>
              <td>
                {t.actorId instanceof Gazebo.WalletsAdmin.ClubActorId
                  ? t.actorId.clubDisplayId
                  : t.actorId instanceof Gazebo.WalletsAdmin.UserActorId
                  ? t.actorId.userDisplayId
                  : 'unknown'}
              </td>
              <td>
                {moment(t.happenedAtMs.toNumber()).format('DD.MM.YYYY HH:mm')}
              </td>
              <td>
                {t.type instanceof Gazebo.WalletsAdmin.Cashout
                  ? 'Cashout'
                  : 'Deposit'}
              </td>
              <td>{centsToMainUnits(t.amount.toNumber())}</td>
              <td>
                {t.acceptor instanceof Gazebo.WalletsAdmin.AgentParticipant ||
                t.acceptor instanceof Gazebo.WalletsAdmin.UserParticipant
                  ? t.acceptor.displayId
                  : 'unknown'}
              </td>
              <td>
                {t.acceptor instanceof Gazebo.WalletsAdmin.AgentParticipant ||
                t.acceptor instanceof Gazebo.WalletsAdmin.UserParticipant
                  ? t.acceptor.name
                  : 'unknown'}
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </>
  );
};
