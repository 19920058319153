import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Switch,
} from '@blueprintjs/core';

import {Ice} from 'ice';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';

import {useRequest} from 'src/utils/useRequest';

import {useAuthContext} from '../../auth';
import {getResponseData, getResponseError} from '../../ice-client-react';
import {logger} from '../../logger';
import {useClub} from '../../store/clubs';
import {useWalletsAdminPrx} from '../../store/walletsAdmin';
import {create} from '../../utils/create';
import {getClasses} from '../../utils/css';
import {Form} from '../../utils/form';
import {useIdempotenceKey} from '../../utils/idempotencyKey';
import {parseCents, parseInteger, useInputState} from '../../utils/inputState';
import {centsToMainUnits} from '../../utils/numbers';
import {ResponseErrorToast} from '../../utils/toast';
import {useCancelContext} from '../../utils/useCancelContext';

import {BalanceInfoProvider, useBalanceInfo} from './BalanceInfoContext';
import {UserBalancesDownload} from './UserBalancesDownload';

const classes = getClasses({
  form: {
    $nest: {
      [`& .${Classes.FORM_GROUP}.${Classes.INLINE}`]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      },
    },
  },
  refillGroup: {
    display: 'grid',
    gridTemplateColumns: '1fr 80px 80px',
    gridGap: 10,
  },
});

export const BalancePage: React.FunctionComponent<{
  clubOrDisplayId: string;
}> = ({clubOrDisplayId}) => {
  const response = useClub(clubOrDisplayId);
  const club = getResponseData(response);
  const error = getResponseError(response);

  if (error != null) {
    return <div>Error</div>;
  }
  if (club == null) {
    return null;
  }
  return (
    <BalanceInfoProvider clubId={club.clubId}>
      <ClubBalance clubId={club.clubId} />
      <UserBalances clubId={club.clubId} />
    </BalanceInfoProvider>
  );
};

const ClubBalance: React.FunctionComponent<{
  clubId: string;
}> = ({clubId}) => {
  const clubResponse = useClub(clubId);
  const club = getResponseData(clubResponse);
  const clubIsInLeague = club?.leagueId != null;

  const [ctx] = useCancelContext();
  const {changeClubBalance, transferFromUserToClub} = useWalletsAdminPrx();
  const auth = useAuthContext();
  const myUserId = auth.idTokenPayload.sub;

  const {
    clubBalancesSubscriptionState,
    clubGemBalancesSubscriptionState,
    userGemBalancesSubscriptionState,
  } = useBalanceInfo();

  const clubWallet = getResponseData(clubBalancesSubscriptionState);
  const clubGemWallet = getResponseData(clubGemBalancesSubscriptionState);
  const myGemWallet = getResponseData(userGemBalancesSubscriptionState);

  const [changeGemState, gemAmount, , setGemAmount] = useInputState(
    '0',
    parseInteger,
    validateAmount,
  );
  const gemKey = useIdempotenceKey(['gems', clubId, gemAmount]);
  const [gemTransferState, transferGems] = useRequest(
    async () => {
      if (gemAmount.error == null && gemAmount.value > 0) {
        await transferFromUserToClub(
          gemKey,
          clubId,
          myUserId,
          new Ice.Long(gemAmount.value),
          undefined,
          create(Gazebo.WalletsAdmin.Gems, {}),
        );
        setGemAmount('0');
      }
    },
    [transferFromUserToClub, setGemAmount, gemAmount, gemKey, clubId, myUserId],
    ctx,
  );

  const [changeState, amount, , setAmount] = useInputState(
    '0.00',
    parseCents,
    validateAmount,
  );
  const key = useIdempotenceKey([clubId, amount]);
  const [balanceUpdatingState, updateBalance] = useRequest(
    async () => {
      if (amount.error == null && amount.value > 0) {
        await changeClubBalance(key, clubId, new Ice.Long(amount.value));
        setAmount('0.00');
      }
    },
    [setAmount, amount, changeClubBalance, key, clubId],
    ctx,
  );

  const clubGemsLoading =
    gemTransferState?.type === 'started' ||
    clubGemBalancesSubscriptionState.type === 'started';

  const userGemsLoading =
    gemTransferState?.type === 'started' ||
    userGemBalancesSubscriptionState.type === 'started';

  const loading =
    balanceUpdatingState?.type === 'started' ||
    clubBalancesSubscriptionState.type === 'started';

  const err = getResponseError(clubBalancesSubscriptionState);
  if (err) {
    logger.warn({err}, 'Got error in subscription to club balance');
  }

  const chipsBalance = clubWallet?.[0]?.balance.toNumber();
  const label = (
    <>
      Club balance:{' '}
      <span className={loading ? Classes.TEXT_MUTED : ''}>
        {err
          ? 'error'
          : chipsBalance === undefined
          ? ''
          : centsToMainUnits(chipsBalance)}
      </span>
    </>
  );
  const gemBalance = clubGemWallet?.[0]?.balance.toNumber();
  const clubGemLabel = (
    <>
      Club Gem balance:{' '}
      <span className={clubGemsLoading ? Classes.TEXT_MUTED : ''}>
        {err ? 'error' : gemBalance === undefined ? '' : gemBalance}
      </span>
    </>
  );
  const myGemBalance = myGemWallet?.[0]?.balance.toNumber();
  const myGemLabel = (
    <>
      My Gem balance:{' '}
      <span className={userGemsLoading ? Classes.TEXT_MUTED : ''}>
        {err ? 'error' : myGemBalance === undefined ? '' : myGemBalance}
      </span>
    </>
  );

  return (
    <Form className={classes.form}>
      <FormGroup
        inline={true}
        label={label}
        labelFor="text-input"
        helperText={amount.error}
      >
        <div className={classes.refillGroup}>
          <InputGroup
            autoFocus={true}
            value={`${amount.rawValue}`}
            id="text-input"
            onChange={changeState}
            disabled={clubIsInLeague}
          />
          <Button
            type="submit"
            disabled={amount.error != null || clubIsInLeague}
            onClick={updateBalance}
          >
            Refill
          </Button>
          {club ? <UserBalancesDownload clubId={clubId} /> : null}
        </div>
      </FormGroup>
      <FormGroup inline={true} label={clubGemLabel} labelFor="text-input">
        <div className={classes.refillGroup}>
          <InputGroup
            autoFocus={true}
            value={`${gemAmount.rawValue}`}
            id="text-input"
            onChange={changeGemState}
          />
          <Button
            type="submit"
            disabled={gemAmount.error != null}
            onClick={transferGems}
          >
            Transfer
          </Button>
        </div>
      </FormGroup>
      <FormGroup inline={true} label={myGemLabel} labelFor="text-input" />
      <ResponseErrorToast response={balanceUpdatingState} />
      <ResponseErrorToast response={gemTransferState} />
    </Form>
  );
};

const UserBalances: React.FunctionComponent<{clubId: string}> = ({clubId}) => {
  const response = useClub(clubId);
  const club = getResponseData(response);

  const [isAgentWallets, setIsAgentWallets] = React.useState<boolean>(false);

  const handleWalletsType = React.useCallback(() => {
    return setIsAgentWallets(!isAgentWallets);
  }, [setIsAgentWallets, isAgentWallets]);

  const {
    userBalancesSubscriptionState,
    userInfosSubscriptionState,
    agentBalancesSubscriptionState,
  } = useBalanceInfo();

  const displayNames = React.useMemo(
    () =>
      new Map(
        getResponseData(userInfosSubscriptionState)?.map((user) => [
          user.userId,
          user.displayName,
        ]),
      ),
    [userInfosSubscriptionState],
  );
  const displayIds = React.useMemo(
    () =>
      new Map(
        getResponseData(userInfosSubscriptionState)?.map((user) => [
          user.userId,
          user.displayId,
        ]),
      ),
    [userInfosSubscriptionState],
  );

  if (club == null) {
    return <div>Error</div>;
  }

  const userWallets = getResponseData(userBalancesSubscriptionState);
  const agentsWallets = getResponseData(agentBalancesSubscriptionState);

  return (
    <>
      <Switch
        checked={isAgentWallets}
        label="Agents"
        onChange={handleWalletsType}
      />
      {isAgentWallets
        ? agentsWallets?.map((wallet) => {
            const userId = wallet.ownerId.userId ?? '';
            return (
              <AgentBalance
                key={wallet.ownerId.userId}
                id={userId}
                club={club}
                agentBalance={wallet.balance}
                displayName={displayNames.get(userId) ?? userId}
                displayId={displayIds.get(userId)}
              />
            );
          })
        : userWallets?.map((wallet) => {
            const userId = wallet.ownerId.userId ?? '';
            return (
              <UserBalance
                key={wallet.ownerId.userId}
                id={userId}
                club={club}
                userBalance={wallet.balance}
                displayName={displayNames.get(userId) ?? userId}
                displayId={displayIds.get(userId)}
              />
            );
          })}
    </>
  );
};

export const UserBalance: React.FunctionComponent<{
  club: Gazebo.Clubs.Club;
  id: string;
  userBalance?: Ice.Long;
  displayName: string;
  displayId: string | undefined;
}> = ({club, id, userBalance, displayName, displayId}) => {
  const {
    transferFromClubToUser,
    transferFromUserToClub,
    transferFromAgentToUser,
    transferFromUserToAgent,
  } = useWalletsAdminPrx();
  const [ctx] = useCancelContext();

  const [onAmountChange, amount, , setAmount] = useInputState(
    '0.00',
    parseCents,
    validateAmount,
  );

  const keyFromClubToUser = useIdempotenceKey([club.clubId, amount]);
  const [depositResponse, doDeposit] = useRequest(
    async () => {
      if (amount.error == null && amount.value > 0) {
        if (club.isMyClub) {
          await transferFromClubToUser(
            keyFromClubToUser,
            club.clubId,
            id,
            new Ice.Long(amount.value),
            club.leagueId,
          );
        } else {
          await transferFromAgentToUser(
            keyFromClubToUser,
            club.clubId,
            id,
            new Ice.Long(amount.value),
            club.leagueId,
          );
        }
        setAmount('0.00');
      }
    },
    [
      transferFromClubToUser,
      transferFromAgentToUser,
      keyFromClubToUser,
      setAmount,
      amount,
      club,
      id,
    ],
    ctx,
  );

  const keyFromUserToClub = useIdempotenceKey([club.clubId, amount]);
  const [cashoutResponse, doCashout] = useRequest(
    async () => {
      if (amount.error == null && amount.value > 0) {
        if (club.isMyClub) {
          await transferFromUserToClub(
            keyFromUserToClub,
            club.clubId,
            id,
            new Ice.Long(amount.value),
            club.leagueId,
          );
        } else {
          await transferFromUserToAgent(
            keyFromUserToClub,
            club.clubId,
            id,
            new Ice.Long(amount.value),
            club.leagueId,
          );
        }
        setAmount('0.00');
      }
    },
    [
      transferFromUserToClub,
      transferFromUserToAgent,
      keyFromUserToClub,
      setAmount,
      amount,
      club,
      id,
    ],
    ctx,
  );

  const loading =
    depositResponse?.type === 'started' || cashoutResponse?.type === 'started';

  const label = (
    <span className={loading ? Classes.TEXT_MUTED : ''}>
      {displayId != null ? displayName + '|' + displayId : displayName}
      {': '}
      {userBalance?.toNumber() === undefined
        ? ''
        : centsToMainUnits(userBalance?.toNumber())}
    </span>
  );

  return (
    <Form className={classes.form}>
      <FormGroup
        inline={true}
        labelFor="text-input"
        label={label}
        helperText={amount.error}
      >
        <div className={classes.refillGroup}>
          <InputGroup
            value={`${amount.rawValue}`}
            id="text-input"
            onChange={onAmountChange}
          />
          <Button
            type="submit"
            disabled={amount.error != null}
            onClick={doDeposit}
          >
            Deposit
          </Button>
          <Button
            type="submit"
            disabled={amount.error != null}
            onClick={doCashout}
          >
            Cashout
          </Button>
        </div>
      </FormGroup>
      <ResponseErrorToast response={depositResponse} />
      <ResponseErrorToast response={cashoutResponse} />
    </Form>
  );
};

export const AgentBalance: React.FunctionComponent<{
  club: Gazebo.Clubs.Club;
  id: string;
  agentBalance?: Ice.Long;
  displayName: string;
  displayId: string | undefined;
}> = ({club, id, agentBalance, displayName, displayId}) => {
  const {transferFromClubToAgent, transferFromAgentToClub} =
    useWalletsAdminPrx();

  const [ctx] = useCancelContext();

  const [onAmountChange, amount, , setAmount] = useInputState(
    '0.00',
    parseCents,
    validateAmount,
  );

  const keyFromClubToUser = useIdempotenceKey([club.clubId, amount]);
  const [depositResponse, doDeposit] = useRequest(
    async () => {
      if (amount.error == null && amount.value > 0) {
        await transferFromClubToAgent(
          keyFromClubToUser,
          club.clubId,
          id,
          new Ice.Long(amount.value),
          club?.leagueId,
        );
        setAmount('0.00');
      }
    },
    [transferFromClubToAgent, keyFromClubToUser, setAmount, amount, club, id],
    ctx,
  );

  const keyFromUserToClub = useIdempotenceKey([club.clubId, amount]);
  const [cashoutResponse, doCashout] = useRequest(
    async () => {
      if (amount.error == null && amount.value > 0) {
        await transferFromAgentToClub(
          keyFromUserToClub,
          club.clubId,
          id,
          new Ice.Long(amount.value),
          club?.leagueId,
        );
        setAmount('0.00');
      }
    },
    [transferFromAgentToClub, keyFromUserToClub, setAmount, amount, club, id],
    ctx,
  );

  const loading =
    depositResponse?.type === 'started' || cashoutResponse?.type === 'started';

  const label = (
    <span className={loading ? Classes.TEXT_MUTED : ''}>
      {displayId != null ? displayName + '|' + displayId : displayName}
      {': '}
      {agentBalance?.toNumber() === undefined
        ? ''
        : centsToMainUnits(agentBalance?.toNumber())}
    </span>
  );

  return (
    <Form className={classes.form}>
      <FormGroup
        inline={true}
        labelFor="text-input"
        label={label}
        helperText={amount.error}
      >
        <div className={classes.refillGroup}>
          <InputGroup
            value={`${amount.rawValue}`}
            id="text-input"
            onChange={onAmountChange}
          />
          <Button
            type="submit"
            disabled={amount.error != null}
            onClick={doDeposit}
          >
            Deposit
          </Button>
          <Button
            type="submit"
            disabled={amount.error != null}
            onClick={doCashout}
          >
            Cashout
          </Button>
        </div>
      </FormGroup>
      <ResponseErrorToast response={depositResponse} />
      <ResponseErrorToast response={cashoutResponse} />
    </Form>
  );
};

const validateAmount = (value: number) => {
  if (Number.isNaN(value) || value <= 0 || !Number.isInteger(value)) {
    // Тут валидируется распарсенное значение, поэтому сообщение об ошибке не
    // полностью соответствует условию.
    return 'amount must be a positive number';
  }
  return;
};
