import {Gazebo} from '@slices/Gazebo';

import {useIceProxy} from '../ice-client-react';

export const useLobbyManagerPrx = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.LobbyManager',
    proxyClass: Gazebo.LobbyManager.ServicePrx,
  });
