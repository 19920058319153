import {
  Button,
  Classes,
  FormGroup,
  HTMLTable,
  InputGroup,
  Position,
  Tab,
  Tabs,
  Toaster,
} from '@blueprintjs/core';
import {Tooltip2} from '@blueprintjs/popover2';
import {Ice} from 'ice';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';

import {create} from 'src/utils/create';
import {useRequest} from 'src/utils/useRequest';

import {getResponseData, getResponseError} from '../../ice-client-react';
import {useClub} from '../../store/clubs';
import {useLeague, useLeaguesProxy} from '../../store/leagues';
import {getClasses} from '../../utils/css';
import {useIdempotenceKey} from '../../utils/idempotencyKey';
import {
  parseString,
  skipValidation,
  useInputState,
} from '../../utils/inputState';
import {centsToMainUnits, mainUnitsToCents} from '../../utils/numbers';
import {ResponseErrorToast} from '../../utils/toast';
import {useCancelContext} from '../../utils/useCancelContext';

import {BalanceManagement} from './BalanceManagement';
import {Report} from './report';

const classes = getClasses({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  noLeagueButtons: {
    display: 'flex',
    justifyContent: 'right',
    flexDirection: 'column',
    $nest: {
      '& > *': {
        margin: '5px',
      },
    },
  },
  joinLeague: {
    display: 'flex',
    $nest: {
      '& > *': {
        margin: '5px',
      },
    },
  },
});

const toaster = Toaster.create({
  position: Position.TOP,
});

export const LeagueManagement: React.FC<{clubOrDisplayId: string}> = ({
  clubOrDisplayId,
}) => {
  const response = useClub(clubOrDisplayId);
  const club = getResponseData(response);
  const error = getResponseError(response);

  if (error != null) {
    return <div>Error</div>;
  }
  if (club == null) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Tabs id="LeagueTabs">
        <Tab
          id="main"
          title="Main"
          panel={
            club.leagueId != null ? (
              <League leagueId={club.leagueId} />
            ) : (
              <NoLeagueButtons club={club} />
            )
          }
        />
        <Tab id="getData" title="Get data" panel={<Report club={club} />} />
        <Tab
          id="balances"
          title="Balances"
          panel={<BalanceManagement club={club} />}
        />
      </Tabs>
    </div>
  );
};

const NoLeagueButtons: React.FC<{club: Gazebo.Clubs.Club}> = ({club}) => {
  const [onLeagueIdChange, leagueId] = useInputState(
    '',
    parseString,
    skipValidation,
  );

  const [onLeagueNameChange, leagueName] = useInputState(
    '',
    parseString,
    skipValidation,
  );

  const [ctx] = useCancelContext();

  const {createLeague, joinLeague} = useLeaguesProxy();
  const createLeagueKey = useIdempotenceKey([club.clubId]);
  const [leagueCreatingState, createLeagueCallback] = useRequest(
    () => createLeague(createLeagueKey, club.clubId, leagueName.value),
    [createLeagueKey, club, leagueName, createLeague],
    ctx,
  );

  const joinKey = useIdempotenceKey([club.clubId]);
  const [joinLeagueState, joinLeagueCallback] = useRequest(
    () => joinLeague(joinKey, leagueId.value, club.clubId),
    [joinKey, club, joinLeague, leagueId],
    ctx,
  );

  return (
    <div className={classes.noLeagueButtons}>
      <div className={classes.joinLeague}>
        <InputGroup value={leagueName.rawValue} onChange={onLeagueNameChange} />
        <Button
          text={'Create League'}
          onClick={createLeagueCallback}
          disabled={club.leagueId != null}
          loading={leagueCreatingState?.type === 'started'}
        />
      </div>
      <div className={classes.joinLeague}>
        <InputGroup value={leagueId.rawValue} onChange={onLeagueIdChange} />
        <Button
          text={'Join League'}
          onClick={joinLeagueCallback}
          disabled={club.leagueId != null}
          loading={joinLeagueState?.type === 'started'}
        />
      </div>
      <ResponseErrorToast response={leagueCreatingState} />
      <ResponseErrorToast response={joinLeagueState} />
    </div>
  );
};

const League: React.FC<{leagueId: string}> = ({leagueId}) => {
  const leagueResponse = useLeague(leagueId);
  const league = getResponseData(leagueResponse)?.[0];

  if (league == null) {
    return <div>Error</div>;
  }

  return (
    <div>
      <div>League Id: {league.displayId}</div>
      <div>League Name: {league.name}</div>
      <ClubsManagement league={league} />
    </div>
  );
};

export const ClubsManagement: React.FC<{league: Gazebo.Leagues.League}> = ({
  league,
}) => {
  const [state, setState] = React.useState<React.ReactText>();
  return (
    <Tabs id="Clubs" onChange={setState} selectedTabId={state}>
      <Tab
        id="pending"
        title="Pending requests"
        panel={
          <PendingRequestTable
            leagueId={league.leagueId}
            clubsInfo={league.leagueClubsInfo}
            clubIds={league.clubWaitingForApprovalIds}
          />
        }
      />
      <Tab
        id="clubs"
        title="Clubs"
        panel={
          <MemberTable
            leagueId={league.leagueId}
            clubs={league.clubs}
            clubsInfo={league.leagueClubsInfo}
          />
        }
      />
    </Tabs>
  );
};

const MemberTable: React.FC<{
  leagueId: string;
  clubs: Gazebo.Leagues.Club[];
  clubsInfo: Gazebo.Leagues.CommonClubInfoSeq | undefined;
}> = ({leagueId, clubs, clubsInfo}) => {
  if (clubs.length === 0) {
    return <div>No clubs in league</div>;
  }
  return (
    <HTMLTable>
      <thead>
        <tr>
          <th>ID</th>
          <th>Stop loss</th>
          <th>Daily profit</th>
          <th>Weekly profit</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {clubs.map((club) => (
          <MemberRow
            key={club.clubId}
            leagueId={leagueId}
            club={club}
            clubInfo={clubsInfo?.find(
              (clubInfo) => clubInfo.clubId === club.clubId,
            )}
          />
        ))}
      </tbody>
    </HTMLTable>
  );
};

const MemberRow: React.FC<{
  leagueId: string;
  club: Gazebo.Leagues.Club;
  clubInfo: Gazebo.Leagues.CommonClubInfo | undefined;
}> = ({leagueId, club, clubInfo}) => {
  const dailyStoploss = club.stoplosses?.daily;
  const weeklyStoploss = club.stoplosses?.weekly;
  const clubId = club.clubId;

  // если с clubInfo проблемы, используем id вместо имени
  const name = clubInfo?.name || clubId;
  const displayId = clubInfo?.displayId || '';

  const parseFloat = React.useCallback(
    (raw) => mainUnitsToCents(Number.parseFloat(raw)),
    [],
  );
  const validate = React.useCallback((value) => {
    if (Number.isNaN(value)) {
      return 'Value is not valid';
    } else if (value < 0) {
      return 'Value is negative';
    }
  }, []);
  const [
    handleChangeWeeklyStoploss,
    weeklyStoplossState,
    _,
    setWeeklyStoploss,
  ] = useInputState('', parseFloat, validate);
  const [handleChangeDailyStoploss, dailyStoplossState, , setDailyStoploss] =
    useInputState('', parseFloat, validate);

  const [isDailyStoplossEditable, setIsDailyStoplossEditable] =
    React.useState(false);
  const [isWeeklyStoplossEditable, setIsWeeklyStoplossEditable] =
    React.useState(false);
  const handleDailyEditClick = React.useCallback(() => {
    setIsDailyStoplossEditable(true);
  }, [setIsDailyStoplossEditable]);
  const handleDailyClose = React.useCallback(() => {
    setIsDailyStoplossEditable(false);
    setDailyStoploss(
      dailyStoploss?.limit
        ? centsToMainUnits(dailyStoploss.limit.toNumber())
        : '0',
    );
  }, [setIsDailyStoplossEditable, setDailyStoploss, dailyStoploss]);
  const handleWeeklyEditClick = React.useCallback(() => {
    setIsWeeklyStoplossEditable(true);
  }, [setIsWeeklyStoplossEditable]);
  const handleWeeklyClose = React.useCallback(() => {
    setIsWeeklyStoplossEditable(false);
    setWeeklyStoploss(
      weeklyStoploss?.limit
        ? centsToMainUnits(weeklyStoploss.limit.toNumber())
        : '0',
    );
  }, [setIsWeeklyStoplossEditable, setWeeklyStoploss, weeklyStoploss]);

  React.useEffect(() => {
    setDailyStoploss(
      dailyStoploss?.limit
        ? centsToMainUnits(dailyStoploss.limit.toNumber())
        : '0',
    );
    setWeeklyStoploss(
      weeklyStoploss?.limit
        ? centsToMainUnits(weeklyStoploss.limit.toNumber())
        : '0',
    );
    setIsDailyStoplossEditable(!!dailyStoploss?.enabled);
    setIsWeeklyStoplossEditable(!!weeklyStoploss?.enabled);
  }, [
    dailyStoploss,
    weeklyStoploss,
    setWeeklyStoploss,
    setDailyStoploss,
    setIsDailyStoplossEditable,
    setIsWeeklyStoplossEditable,
  ]);

  const {setupStopLoss, reactivateClub, suspendClub} = useLeaguesProxy();
  const [ctx] = useCancelContext();
  const stopLossIdempotenceKey = useIdempotenceKey([
    leagueId,
    clubId,
    weeklyStoplossState.value,
  ]);
  const [setupStopLossResponse, doSetupStopLoss] = useRequest(
    async () => {
      await setupStopLoss(
        stopLossIdempotenceKey,
        leagueId,
        clubId,
        new Ice.Long(weeklyStoplossState.value),
        create(Gazebo.Leagues.Stoplosses, {
          daily: create(Gazebo.Leagues.Stoploss, {
            enabled: isDailyStoplossEditable,
            limit: new Ice.Long(dailyStoplossState.value),
          }),
          weekly: create(Gazebo.Leagues.Stoploss, {
            enabled: isWeeklyStoplossEditable,
            limit: new Ice.Long(weeklyStoplossState.value),
          }),
        }),
      );
      setIsDailyStoplossEditable(false);
      setIsWeeklyStoplossEditable(false);
      toaster.show({message: 'Stop loss has been set'});
    },
    [
      clubId,
      stopLossIdempotenceKey,
      leagueId,
      setupStopLoss,
      weeklyStoplossState.value,
      dailyStoplossState.value,
      setIsDailyStoplossEditable,
      setIsWeeklyStoplossEditable,
      isDailyStoplossEditable,
      isWeeklyStoplossEditable,
    ],
    ctx,
  );
  const reactivateIdempotenceKey = useIdempotenceKey([Date.now()]);
  const [reactivateClubResponse, doReactivateClub] = useRequest(
    async () => {
      await reactivateClub(reactivateIdempotenceKey, leagueId, clubId);
    },
    [clubId, reactivateIdempotenceKey, leagueId, reactivateClub],
    ctx,
  );
  const suspendIdempotenceKey = useIdempotenceKey([Date.now()]);
  const [suspendClubResponse, doSuspendClub] = useRequest(
    async () => {
      await suspendClub(suspendIdempotenceKey, leagueId, clubId);
    },
    [clubId, suspendIdempotenceKey, leagueId, suspendClub],
    ctx,
  );

  const dailyRightElement = isDailyStoplossEditable ? (
    <>
      <Button
        icon="floppy-disk"
        disabled={!!dailyStoplossState.error}
        onClick={doSetupStopLoss}
        loading={setupStopLossResponse?.type === 'started'}
      />
      <Button
        icon="cross"
        onClick={handleDailyClose}
        disabled={setupStopLossResponse?.type === 'started'}
      />
    </>
  ) : (
    <Button icon="edit" onClick={handleDailyEditClick} />
  );

  const weeklyRightElement = isWeeklyStoplossEditable ? (
    <>
      <Button
        icon="floppy-disk"
        disabled={!!weeklyStoplossState.error}
        onClick={doSetupStopLoss}
        loading={setupStopLossResponse?.type === 'started'}
      />
      <Button
        icon="cross"
        onClick={handleWeeklyClose}
        disabled={setupStopLossResponse?.type === 'started'}
      />
    </>
  ) : (
    <Button icon="edit" onClick={handleWeeklyEditClick} />
  );

  const intent = weeklyStoplossState.error ? 'danger' : 'none';
  return (
    <tr>
      <ResponseErrorToast response={setupStopLossResponse} />
      <ResponseErrorToast response={reactivateClubResponse} />
      <ResponseErrorToast response={suspendClubResponse} />
      <td>
        <Tooltip2
          className={Classes.TOOLTIP_INDICATOR}
          content={clubId}
          position={Position.TOP}
        >
          {`${name} (${displayId})`}
        </Tooltip2>
      </td>
      <td>
        <FormGroup helperText={dailyStoplossState.error} intent={intent}>
          <InputGroup
            intent={intent}
            onChange={handleChangeDailyStoploss}
            rightElement={dailyRightElement}
            disabled={!isDailyStoplossEditable}
            value={
              isDailyStoplossEditable
                ? dailyStoplossState.rawValue
                : dailyStoploss?.limit
                ? centsToMainUnits(dailyStoploss.limit.toNumber())
                : 'Not set'
            }
          />
        </FormGroup>
        <FormGroup helperText={weeklyStoplossState.error} intent={intent}>
          <InputGroup
            intent={intent}
            onChange={handleChangeWeeklyStoploss}
            rightElement={weeklyRightElement}
            disabled={!isWeeklyStoplossEditable}
            value={
              isWeeklyStoplossEditable
                ? weeklyStoplossState.rawValue
                : weeklyStoploss?.limit
                ? centsToMainUnits(weeklyStoploss.limit.toNumber())
                : 'Not set'
            }
          />
        </FormGroup>
      </td>
      <td>
        {club.stoplosses?.daily instanceof Gazebo.Leagues.StoplossFull
          ? centsToMainUnits(club.stoplosses?.daily.profit.toNumber())
          : 'unknown'}
      </td>
      <td>
        {club.stoplosses?.weekly instanceof Gazebo.Leagues.StoplossFull
          ? centsToMainUnits(club.stoplosses?.weekly.profit.toNumber())
          : 'unknown'}
      </td>
      <td>
        {club.isSuspended ? (
          <Button onClick={doReactivateClub}>Reactivate</Button>
        ) : (
          <Button onClick={doSuspendClub}>Suspend</Button>
        )}
      </td>
    </tr>
  );
};

const PendingRequestTable: React.FC<{
  leagueId: string;
  clubIds: string[];
  clubsInfo: Gazebo.Leagues.CommonClubInfoSeq | undefined;
}> = ({clubIds, leagueId, clubsInfo}) => {
  if (clubIds.length === 0) {
    return <div>No pending requests in league</div>;
  }
  return (
    <HTMLTable>
      <thead>
        <tr>
          <th>ID</th>
        </tr>
      </thead>
      <tbody>
        {clubIds.map((clubId) => (
          <PendingRequestRow
            key={clubId}
            clubId={clubId}
            leagueId={leagueId}
            clubInfo={clubsInfo?.find((clubInfo) => clubInfo.clubId === clubId)}
          />
        ))}
      </tbody>
    </HTMLTable>
  );
};

const PendingRequestRow: React.FC<{
  clubId: string;
  leagueId: string;
  clubInfo: Gazebo.Leagues.CommonClubInfo | undefined;
}> = ({clubId, leagueId, clubInfo}) => {
  const [ctx] = useCancelContext();
  const leaguesPrx = useLeaguesProxy();

  //если с clubInfo проблемы, используем id вместо имени
  const name = clubInfo?.name || clubId;
  const displayId = clubInfo?.displayId || '';

  const approveKey = useIdempotenceKey([leagueId, clubId]);
  const [acceptResponse, doApproveJoinRequest] = useRequest(
    () => leaguesPrx.approveJoinRequest(approveKey, clubId, leagueId),
    [leaguesPrx, approveKey, clubId, leagueId],
    ctx,
  );

  const joinKey = useIdempotenceKey([leagueId, clubId]);
  const [declineResponse, doRejectJoinRequest] = useRequest(
    () => leaguesPrx.declineJoinRequest(joinKey, clubId, leagueId),
    [leaguesPrx, joinKey, clubId, leagueId],
    ctx,
  );

  const disabled =
    acceptResponse?.type === 'started' || declineResponse?.type === 'started';

  return (
    <tr>
      <td className={disabled ? Classes.TEXT_MUTED : ''}>
        <Tooltip2
          className={Classes.TOOLTIP_INDICATOR}
          content={clubId}
          position={Position.TOP}
        >
          {`${name} (${displayId})`}
        </Tooltip2>
      </td>
      <td>
        <Button
          disabled={disabled}
          loading={acceptResponse?.type === 'started'}
          minimal={true}
          icon="tick"
          onClick={doApproveJoinRequest}
        />
      </td>
      <td>
        <Button
          disabled={disabled}
          loading={declineResponse?.type === 'started'}
          minimal={true}
          icon="cross"
          onClick={doRejectJoinRequest}
        />
      </td>
      <ResponseErrorToast response={declineResponse} />
      <ResponseErrorToast response={acceptResponse} />
    </tr>
  );
};
