import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {useIceProxy, useSubscription} from '../ice-client-react';

export const useLeaguesProxy = () =>
  useIceProxy({
    type: 'static',
    propertyName: 'Webapp.Leagues',
    proxyClass: Gazebo.Leagues.ServicePrx,
  });

export const useLeague = (leagueId: string | undefined) => {
  const {subscribeToLeague} = useLeaguesProxy();
  const subscribe = React.useMemo(
    () =>
      leagueId != null
        ? (
            subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.Leagues.League>,
          ) => subscribeToLeague(subscriber, leagueId)
        : undefined,
    [subscribeToLeague, leagueId],
  );

  return useSubscription(subscribe);
};
