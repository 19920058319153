import {
  cancelable,
  CancelContext,
  catchCancelError,
  createCancelContext,
  isCancelError,
  race,
  retry,
} from '@neolab/cancel-context';
import {Ice} from 'ice';

import {Ganymede} from '@slices/Ganymede';
import {Ingress} from '@slices/Ingress';

import {logger} from '../logger';

import './iceExceptionPatch';
import './iceLongPatch';
import './iceValuePatch';
import {catchNullPrxError, NullPrxError} from './NullPrxError';
import {DynamicProxyConfig, StaticProxyConfig} from './proxyConfig';
import {bindProxy, createSubject, deferred, PromisifyPrx} from './utils';

export * from './proxyConfig';
export * from './NullPrxError';

export const buildIceProperties = (propertyPrefix: string) => {
  const properties = Ice.createProperties();

  properties.setProperty('Ice.Default.Host', window.location.hostname);
  properties.setProperty('Ice.RetryIntervals', '-1');

  const process: {argv: string[]; env?: {[key: string]: string}} | undefined = (
    window as any
  ).process;

  const argv = process ? process.argv : [];
  const env = (process && process.env) || {};

  if (propertyPrefix != null) {
    properties.parseCommandLineOptions(propertyPrefix, argv);
  }

  for (const key of Object.keys(env)) {
    properties.setProperty(key, env[key]);
  }

  return properties;
};

export type ConnectionState =
  | {type: 'established'}
  | {type: 'retry'; error?: any; attempt: number; delayMs: number};

export const createIceClient = (properties: Ice.Properties) => {
  const [ctx, cancel] = createCancelContext();
  const {promise: iceClient, resolve: setIceClient} = deferred<{
    communicator: Ice.Communicator;
    router: Ingress.RouterPrx;
    adapter: Ice.ObjectAdapter;
  }>(ctx);
  const currentAccessToken = createSubject<string>(ctx);
  const currentCategory = createSubject<string>(ctx);
  const connectionClosedSubject = createSubject<Ice.Connection>(ctx);
  const connectionStateSubject = createSubject<ConnectionState>(ctx);
  const forceReconnectSignal = createSubject<undefined>(ctx);

  const connectToIngress = (
    ingresPrx: Ice.ObjectPrx | null,
  ): Ingress.RouterPrx => {
    const router = Ingress.RouterPrx.uncheckedCast(ingresPrx);

    if (router == null) {
      throw new Error('Ice.Default.Router property not set');
    }

    return router;
  };

  (async function keepConnection() {
    let category = '';
    const iceClient = await retry(
      ctx,
      async () => {
        const initData = new Ice.InitializationData();
        initData.properties = properties;

        // ice modifies args array so it must be copied
        const communicator = Ice.initialize([...process.argv], initData);

        const router = connectToIngress(communicator.getDefaultRouter());

        category = await cancelable(ctx, router.getCategory());

        const adapter = await cancelable(
          ctx,
          communicator.createObjectAdapterWithRouter('', router),
        );

        return {router, adapter, communicator};
      },
      {
        baseMs: 1000,
        maxDelayMs: 10000,
        onError(event) {
          connectionStateSubject.put({type: 'retry', ...event});
        },
      },
    );

    setIceClient(iceClient);
    currentCategory.put(category);

    const reconnectToIngress = async (
      ctx: CancelContext,
      wrongCategory?: boolean,
    ): Promise<void> => {
      try {
        if (wrongCategory) {
          await cancelable(ctx, iceClient.router.getCategory()).then(
            (category) => {
              currentCategory.put(category);
            },
          );
        } else {
          await cancelable(ctx, iceClient.router.reconnect(category));
        }
      } catch (err) {
        if (isCancelError(err)) {
          throw err;
        }
        if (err instanceof Ingress.CategoryNotExistException) {
          logger.debug({err}, 'Ingress.CategoryNotExistException');
          return await reconnectToIngress(ctx, true);
        }
        logger.error({err}, 'Failed to connect to ingress');
        throw err;
      }
    };

    const worker = (): Promise<unknown> =>
      race(ctx, (ctx) => ({
        connection: retry(ctx, reconnectToIngress, {
          baseMs: 1000,
          maxDelayMs: 10000,
          onError(event) {
            connectionStateSubject.put({type: 'retry', ...event});
          },
        }),
        reconnectNow: forceReconnectSignal.next(),
      })).then((result) => {
        if ('reconnectNow' in result) {
          return worker();
        }
        return result;
      });

    while (true) {
      const connection: Ice.Connection =
        iceClient.router.ice_getCachedConnection()!;

      connection.setACM(
        30,
        Ice.ACMClose.CloseOnIdle,
        Ice.ACMHeartbeat.HeartbeatAlways,
      );
      connection.setCloseCallback((connection) => {
        connectionClosedSubject.put(connection);
      });

      connectionStateSubject.put({type: 'established'});

      await connectionClosedSubject.next();

      await worker();
    }
  })().catch(catchCancelError);

  const proxies = new Map<string, PromisifyPrx<Ice.ObjectPrx>>();

  function createProxy<P extends Ice.ObjectPrx>(
    config: StaticProxyConfig<P> | DynamicProxyConfig<P>,
  ): PromisifyPrx<P> {
    const updatedConfig = {authenticate: true, ...config};
    const key = `${updatedConfig.type}${updatedConfig.nullable}${
      updatedConfig.authenticate
    }${updatedConfig.proxyClass.ice_staticId()}${
      updatedConfig.type === 'static'
        ? updatedConfig.propertyName
        : updatedConfig.proxyString
    }`;
    if (proxies.has(key)) {
      return proxies.get(key) as PromisifyPrx<P>;
    }
    const proxySubject = createSubject<P>(ctx);
    iceClient
      .then(({communicator}) => {
        const uncheckedPrx =
          updatedConfig.type === 'static'
            ? communicator.propertyToProxy(updatedConfig.propertyName)
            : communicator.stringToProxy(updatedConfig.proxyString);

        const checkedPrx = updatedConfig.proxyClass.uncheckedCast(uncheckedPrx);
        if (checkedPrx == null) {
          const error = new NullPrxError(updatedConfig);
          proxySubject.cancel(error);
          throw error;
        }

        return checkedPrx;
      })
      .then(proxySubject.put)
      .catch(catchCancelError)
      .catch(catchNullPrxError);

    const prx = updatedConfig.authenticate
      ? bindProxy(
          updatedConfig.proxyClass,
          proxySubject.merge(currentAccessToken, authenticate),
        )
      : bindProxy(updatedConfig.proxyClass, proxySubject);

    proxies.set(key, prx);
    return prx;
  }

  const authenticate = <P extends Ice.ObjectPrx>(prx: P, token: string): P => {
    const context = new Map(prx.ice_getContext());
    context.set(Ganymede.AccessTokenContextKey, token);
    context.set(Ingress.IngressTokenContextKey, token);
    return prx.ice_context(context);
  };

  const authenticateProxy = <P extends Ice.ObjectPrx>(prx: P) => {
    const proxySubject = createSubject<P>(ctx);
    proxySubject.put(prx);
    return bindProxy(
      prx.constructor as any,
      proxySubject.merge(currentAccessToken, authenticate),
    );
  };

  type SubscriptionFactory<
    Subscriber extends Ice.ObjectPrx,
    Subscription extends Ice.ObjectPrx,
  > = (
    subscriberPrx: Subscriber,
    ctx?: Ice.Context | undefined,
  ) => Promise<Subscription | null>;

  type SubscriptionWorker<
    Subscriber extends Ice.ObjectPrx,
    Subscription extends Ice.ObjectPrx,
  > = (
    subscriptionFactory: SubscriptionFactory<Subscriber, Subscription>,
    adapter: Ice.ObjectAdapter,
    servantCategory: string,
  ) => {unsubscribe(): void};

  const createSubscription = <
    Subscriber extends Ice.ObjectPrx,
    Subscription extends Ice.ObjectPrx,
  >(
    subscriptionFactory: SubscriptionFactory<Subscriber, Subscription>,
    worker: SubscriptionWorker<Subscriber, Subscription>,
  ): (() => void) => {
    const [ctx, cancel] = createCancelContext();
    let subscriptionPrx: PromisifyPrx<Subscription> | null = null;
    const factory: SubscriptionFactory<Subscriber, Subscription> = async (
      subscriber,
      ctx,
    ) => {
      const subscription = await subscriptionFactory(subscriber, ctx);
      if (subscription == null) {
        throw new Error('Null prx');
      }
      if (subscriptionPrx != null) {
        subscriptionPrx.$$unbind();
      }
      subscriptionPrx = authenticateProxy(subscription);
      return subscriptionPrx as any;
    };

    (async () => {
      let removeCallback = () => {};
      let categoryPromis = currentCategory.current();
      try {
        while (!ctx.isCanceled()) {
          const {adapter} = await iceClient;
          const category = await categoryPromis;
          removeCallback();
          const subscription = worker(factory, adapter, category);
          removeCallback = ctx.onCancel(() => subscription.unsubscribe());
          categoryPromis = currentCategory.next();
        }
      } catch (err) {}
    })();
    return () => cancel();
  };

  return {
    getIceClient: () => iceClient,
    onConnectionStateChange: (
      cb: (state: ConnectionState) => void,
    ): (() => void) => {
      const {cancel} = connectionStateSubject.map<void>(cb);
      return () => cancel();
    },
    createProxy,
    createSubscription,
    authenticateProxy,
    setAccessToken(token: string) {
      currentAccessToken.put(token);
    },
    reconnectNow: () => {
      forceReconnectSignal.put(undefined);
    },
    destroy: () => {
      cancel();
      return iceClient.then(({communicator}) => communicator.destroy());
    },
  };
};

export type IceClient = ReturnType<typeof createIceClient>;
