import React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {
  getResponseData,
  SubscriptionState,
  useSubscription,
} from '../../ice-client-react';

import {useClub} from '../../store/clubs';
import {useUserInfoPrx} from '../../store/userInfo';
import {useWalletsAdminPrx} from '../../store/walletsAdmin';
import {nullableToList} from '../../utils/emptyList';

import {useAuthContext} from '../../auth';

type BalanceInfoContextType = {
  clubBalancesSubscriptionState: SubscriptionState<Gazebo.WalletsAdmin.Wallet>;
  clubGemBalancesSubscriptionState: SubscriptionState<Gazebo.WalletsAdmin.Wallet>;
  leagueGemBalancesSubscriptionState: SubscriptionState<Gazebo.WalletsAdmin.Wallet>;
  userGemBalancesSubscriptionState: SubscriptionState<Gazebo.WalletsAdmin.Wallet>;
  userBalancesSubscriptionState: SubscriptionState<Gazebo.WalletsAdmin.Wallet>;
  agentBalancesSubscriptionState: SubscriptionState<Gazebo.WalletsAdmin.Wallet>;
  userInfosSubscriptionState: SubscriptionState<Gazebo.UserInfo.User>;
} | null;

const BalanceInfoContext = React.createContext<BalanceInfoContextType>(null);

export function useBalanceInfo() {
  const context = React.useContext(BalanceInfoContext);
  if (!context) {
    throw new Error('useBalance must be used within a BalanceProvider');
  }
  return context;
}
const NULL_PROMISE: Promise<null> = new Promise(() => null);

// подписки для страницы баланса
export function BalanceInfoProvider(props: any) {
  const {clubId} = props;

  const clubResponse = useClub(clubId);
  const club = getResponseData(clubResponse);
  const leagueId = club?.leagueId;
  const auth = useAuthContext();
  const myUserId = auth.idTokenPayload.sub;
  const agentIds = React.useMemo(() => {
    return club?.isMyClub ? nullableToList(club?.agentUserIds) : [myUserId];
  }, [club, myUserId]);
  const userIds = React.useMemo(() => {
    const agentUserIds = [...(club?.userAgents?.entries() ?? [])]
      .filter(([_, agentUserId]) => agentUserId === myUserId)
      .map(([id]) => id);
    return club?.isMyClub
      ? nullableToList(club.userIds)
      : [...agentUserIds, myUserId];
  }, [club, myUserId]);

  const {
    subscribeToClubBalances,
    subscribeToUserBalances,
    subscribeToAgentBalances,
    subscribeToClubGemBalances,
    subscribeToUserGemBalances,
    subscribeToLeagueGemBalances,
  } = useWalletsAdminPrx();

  const subscribe = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.WalletsAdmin.Wallet>,
    ) => subscribeToClubBalances(subscriber, [clubId], club?.leagueId),
    [clubId, subscribeToClubBalances, club],
  );
  const clubBalancesSubscriptionState = useSubscription(subscribe);

  const subscribeToGemBalances = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.WalletsAdmin.Wallet>,
    ) => subscribeToClubGemBalances(subscriber, [clubId], club?.leagueId),
    [clubId, subscribeToClubGemBalances, club],
  );

  const clubGemBalancesSubscriptionState = useSubscription(
    subscribeToGemBalances,
  );

  const subscribeToLeagueGemBalance = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.WalletsAdmin.Wallet>,
    ) =>
      leagueId
        ? subscribeToLeagueGemBalances(subscriber, [leagueId])
        : NULL_PROMISE,
    [leagueId, subscribeToLeagueGemBalances],
  );

  const leagueGemBalancesSubscriptionState = useSubscription(
    subscribeToLeagueGemBalance,
  );

  const subscribeToMyGemBalances = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.WalletsAdmin.Wallet>,
    ) => subscribeToUserGemBalances(subscriber, [myUserId]),
    [subscribeToUserGemBalances, myUserId],
  );
  const userGemBalancesSubscriptionState = useSubscription(
    subscribeToMyGemBalances,
  );

  const subscribeToBalances = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.WalletsAdmin.Wallet>,
    ) => subscribeToUserBalances(subscriber, userIds, leagueId, clubId),
    [subscribeToUserBalances, clubId, leagueId, userIds],
  );
  const userBalancesSubscriptionState = useSubscription(subscribeToBalances);

  const agentBalancesSubscription = React.useCallback(
    (
      subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.WalletsAdmin.Wallet>,
    ) => subscribeToAgentBalances(subscriber, agentIds, clubId, leagueId),
    [subscribeToAgentBalances, clubId, leagueId, agentIds],
  );

  const agentBalancesSubscriptionState = useSubscription(
    agentBalancesSubscription,
  );

  const {subscribeToUsers} = useUserInfoPrx();
  const subscribeToUserInfos = React.useCallback(
    (subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.UserInfo.User>) =>
      subscribeToUsers(subscriber, userIds),
    [subscribeToUsers, userIds],
  );

  const userInfosSubscriptionState = useSubscription(subscribeToUserInfos);

  const value = {
    clubBalancesSubscriptionState,
    clubGemBalancesSubscriptionState,
    leagueGemBalancesSubscriptionState,
    userGemBalancesSubscriptionState,
    userBalancesSubscriptionState,
    userInfosSubscriptionState,
    agentBalancesSubscriptionState,
  };

  return <BalanceInfoContext.Provider value={value} {...props} />;
}
